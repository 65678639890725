.pages {
    @extend %clearfix;
}
.pages li {
    float:left;
    line-height:1;
    list-style:none;
    margin:0 3px 0 0;
}
.pages li .btn.tertiary.active {
    background:#999;
    background:-webkit-linear-gradient(top, #999, #aaa);
    background:-moz-linear-gradient(top, #999, #aaa);
    background:linear-gradient(top, #999, #aaa);
    box-shadow:inset 0 1px 0 rgba(255,255,255,.3),
        0 1px 1px 0 rgba(18,22,26,.1);
    border:1px solid #777;
    color:#fff;
    cursor:default;
    font-weight:bold;
    text-shadow:0 -1px 0 rgba(0,0,0,.2);
}
.pages li.prev,
.pages li.summary {
    margin-left:10px;
}
.pages li.next,
.pages li.per-page,
.pages li.summary:first-child {
    margin-right:15px;
}
.pages li.prev:first-child{margin-left:0;}
.pages li.next:last-child{margin-right:0;}
.pages li.summary,
.pages li.ellip {
    color:#999;
    font-size:12px;
    line-height:22px;
}