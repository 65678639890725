/*
 * Merge Field Autocomplete
 */
ul.textcomplete-dropdown {
    font-size: 14px;
    background: #fbfbfb;
    border: 1px solid #f4f4f4;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    box-shadow:rgba(0,0,0,.05) 0 1px 2px;
    .textcomplete-header {
        border-radius: 3px 3px 0 0;
    }
    .textcomplete-header,
    .textcomplete-footer {
        height: 2px;
        padding: 0;
    }
    .textcomplete-footer {
        border-radius: 0 0 3px 3px;
    }
    li {
        list-style: none;
        padding: 10px;
        margin: 0;
        min-width: 250px;

        &:hover,
        &.active {
            cursor: pointer;
            background: rgb(250, 255, 189);
        }
    }
}