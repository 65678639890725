/* Stop transitions until the page has loaded */
.preload,
.preload * {
    -webkit-transition:none !important;
    -moz-transition:none !important;
    -ms-transition:none !important;
    -o-transition:none !important;
    transition:none !important;
}
.sidebar,
.app-footer,
.footer,
.error-console {
    -webkit-transition:left .25s ease-in-out;
    -moz-transition:left .25s ease-in-out;
    -ms-transition:left .25s ease-in-out;
    -o-transition:left .25s ease-in-out;
    transition:left .25s ease-in-out;
}

body,
.nav-toggle,
.info,
.error-console {
    -webkit-transition:margin-left .25s ease-in-out;
    -moz-transition:margin-left .25s ease-in-out;
    -ms-transition:margin-left .25s ease-in-out;
    -o-transition:margin-left .25s ease-in-out;
    transition:margin-left .25s ease-in-out;
}

/* JS controlled - Default state is off */
.sidebar-on .sidebar,
.sidebar-on .app-footer {
    left:0;
}
.console-on .main {
    min-height: auto;
}
.console-on .error-console {
    display:block;
    height:300px;
}
.console-on .footer {
    bottom:300px;
}
.console-on .console-ico {
    background-position:-100px -75px;
}
.info-on .main {
    padding-bottom:160px;
}
.info-on .info {
    display:block;
    height:80px;
}
.info-on .footer {
    bottom:100px;
}
.info-on .info-ico {
    background-position:-75px -75px;
}
