.modal {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(6, 19, 26, 0.4);
  min-width: 700px;
  padding: 10px;
}
.modal .header {
  border-radius: 4px 4px 0 0;
  height: auto;
  margin: -10px -10px 10px;
  min-width: inherit;
  padding: 10px 0 0;
  position: relative;
}
.modal .header h1 {
  color: #f8f5f0;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  margin: 0 20px 10px;
}
.modal .header h1 .app-icon {
  background-size: 20px 20px;
  margin: 0 5px 0 3px;
  vertical-align: top;
}
.modal .header span.btn.close {
  background: transparent;
  border: 1px solid #132e40;
  border-color: rgba(19, 46, 64, 0.8);
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
    0 1px 0 rgba(255, 255, 255, 0.1);
  color: #7b8e9b;
  position: absolute;
  right: 9px;
  top: 9px;
}
.modal .header span.btn.close:hover {
  color: #f1f7fb;
}
.modal .action {
  margin: 0 -10px -10px;
  padding: 10px 20px;
}

/* Alt modal */
.modal {
  background: #1f4859;
  background-color: rgba(31, 72, 89, 0.9);
  border: 1px solid #163340;
  border-radius: 4px;
  box-shadow: inset 0 1px 0 #546e80, 0 2px 8px rgba(6, 19, 26, 0.4);
  padding: 0;
}
.modal .header {
  background: transparent;
  box-shadow: none;
  margin: 0;
}
.modal .header h1 {
  margin: 0 20px 10px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.modal .content {
  background: #fff;
  padding: 10px;
}
.modal .action {
  background: transparent;
  margin: 0;
  padding: 10px 20px;
}

/* JS Modal Module */
.modal-wrap {
}
.modal-wrap .modal-overlay {
  display: block;
}
.modal-overlay {
  background: rgba(0, 0, 0, 0.6);
  display: none;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  top: 0;
  z-index: 8888;
}
.modal-window {
  background: $white;
  border-radius: 6px;
  position: absolute;
  z-index: 8888;
  margin-bottom: 60px;
  form {
    .modal-action {
      background: #dcdbdb;
      margin: -20px;
      margin-top: 10px;
      padding: 10px 20px;
      border-radius: 0 0 8px 8px;
    }
  }
}
.modal-window.fixed {
  position: fixed;
}
.modal-window .close {
  position: absolute;
  top: -8px;
  right: -10px;
  z-index: 2;
}
.modal-content {
  padding: 16px;
}
.modal-header {
  background: $white;
  border-radius: 8px 8px 0 0;
  padding: 10px;

  h3 {
    font-weight: 400;
    margin: 0;
  }
}
.modal-body {
  padding: 20px;
}
.modal-close {
  cursor: pointer;
  position: absolute;
  right: -40px;
  top: 0;

  .svg-sprite {
    fill: rgba(255, 255, 255, 0.6);
    height: 32px;
    transition: fill 0.25s ease-in-out;
    width: 32px;
    pointer-events: none;

    &:hover {
      fill: rgba(255, 255, 255, 1);
    }
    &:active {
      pointer-events: none;
    }
  }
  .modal-close--text {
    display: none;
  }
}
.modal-footer {
  background: $grey-40;
  border-radius: 0 0 8px 8px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}
.modal-footer--pager {
  bottom: 0;
  left: 0;
  position: absolute;
  text-align: center;
  .pages {
    display: inline-block;
    margin: 0;
  }
}

/*
 * Fullscreen Create New Modal
 */
.modal-window.lg {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
  overflow-y: auto;
  position: fixed;
  margin-bottom: 0;

  /* Modal Content */
  .modal-content {
    background: $white;
    max-width: none;
  }

  /* Modal Close */
  .modal-close {
    color: $grey-60;
    right: $space-lg;
    top: $space-lg;
    .svg-sprite {
      fill: $grey-60;
    }
    .modal-close--text {
      display: inline;
    }
  }
}

.modal-window {
  &.sm {
    width: 500px;
    top: 10%;
    left: calc(50% - 250px);
  }
  &.md {
    width: 800px;
    top: 10%;
    left: calc(50% - 400px);
  }
  &.xl{
    width: 90%;
    top: 0;
    left: 5%;
    right: 10%;
    bottom: 0;
    margin-bottom: 0;
  }
}

/*
 * Automation Tigger Event Properties Modal
 */
.trigger-event__modal {
  padding: 10px;
  min-width: 800px;
  .conjunct__item {
    max-width: 210px;
  }
  .audience_list {
    top: calc(100% - 10px);
    width: 320px;
  }
}

/* light box modal overlay */
.lb-container {
  display: none;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 5001;
}
.lb-active {
  overflow: hidden;
}
.lb-active .lb-container {
  display: table;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}
.lb {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 20px;
}
.lb-content {
  display: inline-block;
  position: relative;
  box-shadow: 0 10px 10px -10px #000;
  opacity: 0;
  -webkit-transform: translateY(-2%);
  transform: translateY(-2%);
  -webkit-transition-property: -webkit-transform, opacity;
  transition-property: transform, -webkit-transform, opacity;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;

  background: $white;
  text-align: left;
  border: 1px solid #1c1c1c;
  width: 650px;
  padding: 0;
  border-radius: 3px;
}
.lb-content.is-active {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.lb-content > div {
  padding: 15px 20px;
}
.lb-content header {
  background: $grey-40;
  border-bottom: 1px solid #dbdbdb;
  border-radius: 4px 4px 0 0;
  box-shadow: rgba(255, 255, 255, 0.8) 0 1px 0 inset;
  line-height: 20px;
  margin: -15px -20px 15px;
  padding: 15px 20px;
  text-shadow: rgba(255, 255, 255, 0.8) 0 1px 0;
  @extend %clearfix;
}
.lb-content header h3 {
  margin: 0;
}
.lb-content .form-row {
  margin-bottom: 20px;
}
.lb-content .form-row.actions {
  background: $grey-20;
  border-radius: 0 0 4px 4px;
  border-top: #dbdbdb 1px solid;
  margin-bottom: 0;
  margin: 15px -20px -15px;
  padding: 15px 20px;
}

.csv_upload {
  width: 1px;
  height: 1px;
  opacity: 0.01;
  position: absolute;
  z-index: -1000;
}
.form-row {
  .module {
    background: #fcfcfc;
    padding-bottom: 15px;
    label {
      color: rgb(102, 102, 102);
    }
    input {
      width: 50%;
    }
  }
}
.file-selection,
.progress,
.process {
  background: transparent url(uiurl('images/upload.png')) no-repeat 0 50%;
  padding-left: 64px;
  min-height: 54px;
}
.file-selection strong,
.process strong {
  line-height: 54px;
  vertical-align: middle;
}
.progress strong {
  display: inline-block;
  margin-bottom: 5px;
}
.progress-bar,
.process-bar {
  display: inline-block;
  width: 100%;
  height: 10px;
  line-height: 10px;
  border-radius: 50px;
  overflow: hidden;
  background: $grey-20;
  position: relative;
}
.progress-bar .index,
.process-bar .index {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  border-radius: 50px;
  background: #21a1d9;
  box-shadow: 3px 0px 0px #ddd;
  -webkit-transition: width 0.25s ease-in-out;
  transition: width 0.25s ease-in-out;
}
.progress .status {
  color: #9c9c9c;
}
.error .error-detail {
  padding: 15px 20px;
  background: #fcfcfc;
  border: 1px solid #ccc;
  margin-left: 0;
  border-radius: 3px;
  max-height: 200px;
  overflow-y: auto;
}
.suspension-modal {
  width: 600px;
  padding: 20px;

  label {
    color: $charcoal;
  }
}
//
.legacy-modal {
  padding: 10px;
  width: 500px;
  h2 {
    color: $pacific;
  }
  h2,
  p,
  div {
    text-align: center;
  }
  .btn {
    margin: 0 5px;
  }
}
.legacy-modal_footer {
  background: $grey-20;
  padding: 10px;
  margin: 0 -10px -10px;
  border-radius: 0 0 10px 10px;
}
.transition-date {
  background: $grey-20;
  border-radius: 2px;
  font-weight: 700;
  margin: 0 auto;
  padding: 4px 6px;
}
// Custom modal
.modal--opt-in {
  img {
    border-radius: 8px 8px 0 0;
    max-width: 600px;
  }
}
// Restart In-App Automation Modal
.modal--restart-in-app-automation {
  width: 586px;
  .modal-header h2 {
    // THIS IS BAD - bottom margin needs to be removed on h2
    margin-bottom: 0;
  }
}
// Introducing Zoltar
.modal--zoltar-intro {
  max-width: 600px;
  /*
     * Override min-width on body within an iFrame
     */
  body {
    min-width: auto;
  }
}
.modal--test-users {
  min-width: 420px;
}
.modal-csv-upload {
  .modal-window {
    max-width: 600px;
  }
}

.loading-frame {
  background-image: url(uiurl('images/loading@2x.gif')) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 150px 100px !important;
  .ua-container {
    background: $white;
  }
}
/**
 * I don't really belong here but there is lots of work to do to clean up our
 * modals. That's a project for another time and you'll find me here when that
 * day comes.
*/
#customer-feedback {
  overflow: hidden;
  position: relative;
  width: 600px;
  .modal_header {
    border-radius: 8px 8px 0 0;
  }
  .modal_footer {
    border-radius: 0 0 8px 8px;
  }
}
