@import 'base/config';

.trigger-drawer {
  top: 53px;
  bottom: 0;
  right: 0;
  background: $grey-40;
  box-shadow: $shadow-md;
  z-index: 9;
}

.trigger-drawer button {
  display: block;
  position: absolute;
  top: 0;
  left: -36px;
  box-shadow: rgba(0, 0, 0, 0.1) -1px 1px 2px;
  line-height: initial;
}

.drawer {
  max-width: 450px;
  max-height: 90%;
  overflow-x: scroll;
  z-index: 9;
  position: fixed;
  right: 0;
  bottom: 0;
  box-shadow: $shadow-md;
  transform: translate3d(500px, 0, 0);
  transition: transform 0.25s ease-in-out;
  opacity: 0;

  .json-pretty {
    padding-bottom: 150px;
  }
}

.drawer p {
  margin-bottom: $space-md;
}

.-top .drawer,
.-top .trigger-drawer {
  position: fixed !important;
}

.-drawer-open .drawer {
  right: 0;
  transform: translate3d(0, 0, 0);
  position: absolute;
  opacity: 1;
}
