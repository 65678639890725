// Review
.review-section {
  background: $white;
  border: $charcoal-20 1px solid;
  clear: both;
  margin: 0 0 20px;
  h2 {
    border-bottom: $charcoal-20 1px solid;
    margin: 0;
    padding: 10px;
  }
  h3 {
    margin: 0 0 5px;
  }
  p {
    margin: 0 0 10px;
  }
}

.review-group {
  margin: 0;
  padding: 0;
}

.review-group,
.review-list {
  li {
    background: #fbfbfb;
    border: $blue 1px solid;
    color: $blue;
    border-radius: 2px;
    font-size: 13px;
    list-style: none;
    display: inline-block;
    float: left;
    margin: 0 5px 5px 0;
    padding: 5px;
    word-break: break-word;
    &:empty {
      display: none;
    }
  }
  .operand {
    display: inline;
    font-weight: 500;
    margin: 0 4px;
    text-transform: uppercase;
  }
}
.variant-content-review {
  h5 {
    display: inline-block;
    margin-right: 5px;
  }
  li {
    font-size: 14px;
    list-style: none;
    margin: 0 0 5px;
    padding: 0;
    word-wrap: break-word;
  }
}
dl.review-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  fieldset {
    box-sizing: border-box;
    flex: 0 0 85%;
    width: 100%;
    padding: 10px 10px 0 10px;
    div {
      margin-bottom: 0;
    }
  }
  dt {
    box-sizing: border-box;
    border-bottom: $charcoal-20 1px solid;
    color: $charcoal-40;
    flex: 0 0 20%;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    padding: 10px;
    text-transform: uppercase;
    width: 20%;
  }
  dd {
    box-sizing: border-box;
    border-bottom: $charcoal-20 1px solid;
    color: $charcoal;
    font-size: 14px;
    flex: 0 0 80%;
    margin: 0;
    padding: 10px;
    position: relative;
    width: 80%;
    word-wrap: break-word;
  }
  ul,
  p {
    color: $charcoal;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    word-break: break-word;
  }
  p + ul {
    margin-top: 5px;
  }
  .-warning,
  .-error {
    position: absolute;
    right: 10px;
    top: 12px;
  }
  h4 {
    font-size: 13px;
    margin: 10px 0 5px;
    span {
      text-transform: uppercase;
    }
  }
}
.review-section--stacked {
  border-bottom: 0;
  margin-bottom: 0;
}
.review-section--stacked dl.review-list dt,
.review-section--stacked dl.review-list dd {
  border: 0;
}
.review-section--stacked dl.review-list dd + dt {
  border-bottom: $charcoal-20 1px solid;
}
.review-section--stacked:last-of-type {
  border-bottom: $charcoal-20 1px solid;
}
// Child Lists
dl.review-list dl.review-list {
  border: $charcoal-20 1px solid;
  dt:first-of-type,
  dd:first-of-type {
    background: #f5f5f5;
    color: $charcoal;
    text-transform: none;
    font-weight: 500;
  }

  &.merge-fields {
    dt {
      text-transform: none;
    }
  }
}

// media
.image-cell img {
  max-width: 200px;
}

.font-color-charcoal {
  color: $charcoal;
}

.review-section-trigger-combinator {
  color: #1657e5;
  font-weight: 500;
}

.review-section-trigger-list li {
  position: relative;
  padding: 10px 0 20px;
  list-style-position: inside;
}

.review-section-trigger-list li:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px; 
  background-color: #ccc;
}
