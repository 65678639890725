.appselect.util-nav {
  left: 0;
}
.appselect-content {
  background: #fff;
  color: #020202;
  line-height: 1;
  width: 350px;
  @extend %clearfix;
}
.appselect-content .search {
  border-bottom: #020202 1px solid;
  padding: 5px;
}
.appselect-content .search-field input[type='search'] {
  box-shadow: none;
}
.appselect-content > ul {
  margin: 0;
}
.appselect-content > ul > li {
  list-style: none;
  margin: 0;
}
.appselect-content > ul > li > a {
  border-bottom: #000 1px solid;
  display: block;
  padding: 10px 20px;
  margin: 0;
}
.appselect-content > ul > li > a:active,
.appselect-content > ul > li > a:hover,
.appselect-content > ul > li.highlight > a {
  background: #eae9e9;
  color: #020202;
  cursor: pointer;
  text-decoration: none;
}
.appselect-content a:hover {
  text-decoration: none;
}
.appselect-content > ul > li > a h3 {
  color: #020202;
  line-height: 1.3;
  font-size: 16px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
  width: auto;
}
.appselect-content > ul > li > a h3,
.appselect-content > ul > li > a p {
  margin: 0 0 0 50px;
}
.appselect-content > ul > li > a p .meta {
  margin: 0;
  color: #020202;
  font-size: 12px;
  text-transform: uppercase;
}
.appselect-content > ul > li > a .app-icon {
  background-size: 40px;
  border-radius: 4px;
  float: left;
  height: 40px;
  width: 40px;
}
/*
 * App Select w/ One Button
 */
.appselect-content a.btn {
  display: block;
  width: 100%;
  border: none;
  border-radius: 0 0 4px 4px;
  font-size: 12px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  padding: 10px;
}
/*
 * App Select w/ Button Bar
 */
.appselect-content .btn-bar {
  border-radius: 0 0 4px 4px;
  display: flex;
}
.appselect-content .btn-bar a {
  border: none;
  font-size: 12px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  width: 50%;
  white-space: nowrap;
  flex: 1;
}
.appselect-content .btn-bar a:first-child {
  border-right: 1px solid darken($charcoal, 10%);
  border-radius: 0 0 0 4px;
  margin-left: 0;
}
.appselect-content .btn-bar a:last-child {
  border-left: 1px solid darken($charcoal, 10%);
  border-radius: 0 0 4px 0;
}
.appselect-content .btn-bar a span {
  color: #b3b3b3;
}

.appselect-content > .no_results,
.appselect-content > ul > li.no_results {
  color: #fff;
  font-weight: 700;
}
.appselect-content > ul > li.no_results {
  padding: 20px 0;
  text-align: center;
  border-bottom: 1px solid darken($charcoal, 10%);
}
.appselect-content > .no_results {
  padding: 15px 20px 10px;
}
.appselect-content .arrow.up {
  z-index: -1;
}
.appselect-content .btn-bar a:only-of-type {
  width: 100%;
}

.no-arrow-popover-content {
  .appselect-content {
    .title {
      padding: 7px 15px 6px;
      background: $charcoal;
      border-bottom: 1px solid $charcoal;
      border-radius: 4px 4px 0 0;

      h3 {
        color: $charcoal-40;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        padding: 0.25rem 0.5rem;
        text-transform: uppercase;
        margin: 0;
      }
    }
    .app-icon {
      svg {
        width: 36px;
        height: 36px;
      }
    }
    #id_menu_projects_list {
      max-height: 300px;
      overflow-y: auto;
    }
  }
  &.projects-select .popover-content {
    background: inherit;
    padding: 0;
    margin: 0;
  }
}

.search-field {
  position: relative;
}
.search-field > input[type='search'] {
  border-radius: 20px;
  height: 28px;
  line-height: 20px;
  padding-left: 25px;
}
.search-field > .icon {
  background-position: 0 -25px;
  left: 10px;
  position: absolute;
  top: 8px;
}
.search-field + div {
  margin-left: 10px;
}
.search-field > input[type='search']::-webkit-input-placeholder {
  line-height: 20px;
}

@media only screen and (max-width: 768px) {
  .appselect .btn-bar {
    width: auto;
  }
}
