$preview-default-color: #222222;
$preview-margin: 0.70233rem;

.zoltar-preview {
  background-image: url(uiurl('images/preview-zoltar.svg'));
  background-repeat: no-repeat;
  background-position: center top;
  width: 24em;
  height: 560px;
  margin: auto;

  /* Keeps all of the text within the message */
  overflow-wrap: break-word;
  word-wrap: break-word;

  .zoltar-preview-buttons {
    margin-top: $preview-margin;

    button {
      width: 100%;
      border-radius: 0;
      background: $preview-default-color;
      color: $white;
      border: 1px solid $preview-default-color;
      text-align: center;
      cursor: auto;
      padding: 8px;
      font-weight: bold;
    }

    /* One button is full-width */
    button:only-child {
      width: 100%;
    }
  }

  .button-layout--stacked {
    button:nth-child(2) {
      margin-top: $preview-margin;
    }
  }

  .button-layout--separate {
    display: flex;
    justify-content: space-between;

    button {
      width: 48.5%; /* Two buttons are half-width with some space in between */
    }
  }

  .button-layout--joined {
    display: flex;
    justify-content: space-between;

    button {
      width: 50%;
    }

    /* makes joined corners 0 radius */
    button:first-of-type {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    button:last-of-type {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  .default-message {
    box-sizing: content-box;
    color: $preview-default-color;
    margin: auto;
    padding: $space-sm;
    position: relative;
    width: 270px;
    height: 480px;

    .media-placeholder {
      min-height: 120px;
    }

    h3,
    p {
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
      color: $preview-default-color;
    }

    h3 {
      text-transform: none;
      font-size: 18px;
      line-height: 21px;
      margin-bottom: $preview-margin;
    }

    p {
      margin-bottom: 0; /* Removes global space on paragraphs */
      font-size: 14px;
      line-height: 17px;
    }

    img {
      width: 100%;
    }
  }

  .banner-preview {
    position: absolute;
    margin: auto;
    top: 3.7rem;
    left: 0;
    right: 0;
    width: 75%;
    background-color: $white;

    img,
    .media-placeholder {
      width: 80px;
    }

    /* Dismiss nub */
    .banner-dismiss {
      height: 0.4rem;
      width: 2.6rem;
      background: $preview-default-color;
      border-radius: $border-radius-lg;
      margin: 0 auto;
    }

    /* IE bug fix for text run off */
    .banner-content {
      max-width: 5.6rem;
    }
  }

  .banner--bottom {
    bottom: 0;
    top: auto;
  }

  .full-screen {
    position: relative;
    margin: auto;
    top: 50px;
    width: 268px;
    height: 449px;
    overflow: hidden;
    overflow-y: scroll;
    background-color: $white;

    a.footer-link {
      color: $preview-default-color;
      justify-content: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding-bottom: $space-xs;
    }
    a.footer-link-old {
      text-decoration: underline;
      color: $preview-default-color;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding-bottom: $space-xs;
    }

    /* Dismiss X */
    .full-screen__dismiss {
      position: absolute;
      right: 0.8rem;
      top: 0.8rem;
    }

    svg.full-screen__dismiss {
      fill: $preview-default-color;
    }
  }

  .custom-zoltar {
    position: relative;
    margin: auto;
    overflow: hidden;
    overflow-y: scroll;
    background-color: $white;
    width: 265px;
    height: 444px;
  }

  .template-preview {
    height: 100%;
    width: 100%;
    border: none;
  }

  .modal-preview {
    max-height: 250px;
    overflow: scroll;
    margin-top: 5.7rem !important;
    background-color: $white;
  }

  .modal-preview,
  .modal-buttons {
    width: 80%;
    left: 0;
    right: 0;
    margin: auto;
  }

  .modal-buttons {
    background-color: $white;
    box-sizing: border-box;

    .button-layout--stacked {
      display: block;
    }
  }

  /* Dismiss X */
  .modal__dismiss {
    position: absolute;
    right: 2.9rem;
    margin-top: 0.3rem;
  }

  svg.modal__dismiss {
    fill: $preview-default-color;
  }
}

.zoltar-inputs {
  max-width: 700px;
}

/* Positions remove icon in button widget */

.zoltar-button-remove {
  position: absolute;
  margin-top: -3rem;
  right: $space-xs;
}
