@import 'settings', 'third-party/zendesk', 'third-party/highlight',
  //  * New Components
  'components/logical',
  'components/drawer',
  //  * Legacy Components
  'components/grid',
  'components/lists', 'components/forms', 'components/tables',
  'components/modules', 'components/protips', 'components/modals',
  'components/popovers', 'components/tabs', 'components/tab-nav',
  'components/selections', 'components/pagination', 'components/feedback',
  'components/loading', 'components/icons', 'components/svg-icons',
  'components/metrics', 'components/picker', 'components/date-picker',
  'components/sections', 'components/pills', 'components/review-section',
  'components/accordion-list', 'components/controls', 'components/email-preview',
  'components/zoltar-preview', 'components/create-popover',
  'components/audience-lookup', 'components/audience-lookup',
  'components/merge-field-autocomplete', 'modules/header', 'modules/app-select',
  'modules/sidebar', 'modules/app', 'modules/sub-navigation', 'modules/main',
  'modules/sub-header', 'modules/console', 'modules/behaviors',
  'modules/toolbars', 'modules/v3-audience', 'modules/section-nav',
  'modules/section-header', 'modules/app-nav', 'modules/app-switcher',
  'wip/layout';

// Temp

h1.airship-logo {
  a,
  a:hover {
    background-image: url(uiurl('images/airship-logo.png'));
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    color: transparent;
    display: block;
    min-height: 240px;
    margin: 0 auto 40px;
  }
}

.spy-warning.spy-warning-on-prod .global-header,
.spy-warning.spy-warning-on-prod .message_footer {
  background-size: 60px;
  background-position: 0 42px;
  background-image: url(uiurl('images/customer.png'));
}

/* Add dots here to have access to uiurl */
.dots {
  background: url(uiurl('images/dots.svg'));
}
