@import 'settings';

.section-header {
  margin: 0 auto;
  border-bottom: $charcoal-20 1px solid;
  box-sizing: border-box;
  @extend %clearfix;
}

.primary-action {
  -ms-flex: 1;
  flex: 1;
  text-align: right;
  padding: 0 10px 0 0;
  > div {
    display: inline-block;
    position: relative;
    &.primary-action-takeover {
      position: static;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .section-header {
    .region--secondary {
      -ms-flex: 0;
      flex: 0;
    }
  }
}
