/* Accordion list */
.accord-list > li {
    border-bottom: $charcoal-20 1px solid;
    list-style:none;
    margin:0;
    width: 100%;
    box-sizing: border-box;
}
.accord-list > li:last-child {
    border-bottom:0;
}
.accord-list > li > div:first-child {
    background: $fog;
    background-image:none;
    box-shadow: none;
    color: $charcoal-80;
    cursor:pointer;
    line-height:20px;
    list-style:none;
    margin:0;
    padding:10px 20px;
}
.accord-list > li > div:first-child h4 {
    display:inline-block;
    font-size:14px;
    line-height:20px;
    margin:0;
}
.accord-list > li > div:first-child .actions {
    float:right;
}
.accord-list > li > div:last-child {
    background: $fog;
    clear: both;
    color: $charcoal-80;
    max-height:0;
    overflow:hidden;
    -webkit-transition:max-height 0.5s ease-in-out;
    transition:max-height 0.5s ease-in-out;
}
.accord-list > li.active > div:last-child {
    max-height:1000px;
}
.accord-list > li > div:last-child .accord-content  {
    padding:20px;
}
.accord-list > li.active > div:last-child .accord-content  {
    -webkit-transition:opacity 0.25s ease-in;
    transition:opacity 0.25s ease-in;
    -webkit-animation:slide-fade 0.25s ease-in;
    animation:slide-fade 0.25s ease-in;
}
.accord-list + .module-footer {
    border-top:0;
}
.accord-list:first-child > li:first-child > div:first-child {
    border-radius:4px 4px 0 0;
}
.accord-list > li:last-child > div:first-child,
.accord-list > li.active:last-child > div:last-child {
    border-radius:0 0 4px 4px;
}
.accord-list:only-child > li:only-child > div:first-child {
     border-radius:4px;
}
.accord-list:nth-last-child(2) > li:last-child > div:first-child,
.accord-list:nth-last-child(2) > li.active:last-child > div:last-child,
.accord-list > li.active:last-child > div:first-child {
    border-bottom-left-radius:0;
    border-bottom-right-radius:0;
}
