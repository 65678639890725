ul.list,
ol.list,
dl.list {
  margin: 0 0 15px;
}

/* unordered list */
ul.list li {
  list-style-position: inside;
  list-style-type: disc;
  margin: 5px 0 0 15px;
}
ul.list li ul li {
  list-style-type: circle;
}
ul.list li ul li ul li {
  list-style-type: square;
}
ul.list li ul li ul li ul li {
  list-style-type: circle;
}

/* oredered list */
ol.list li {
  list-style-position: inside;
  list-style-type: decimal;
  margin: 0.5em 0 0 1em;
}
ol.list li ol li {
  list-style-type: upper-alpha;
}
ol.list li ol li ol li {
  list-style-type: lower-alpha;
}
ol.list li ol li ol li ol li {
  list-style-type: upper-roman;
}

ul.list li ul,
ol.list li ol {
  margin-bottom: 0;
}

ul.list.u-list-outside li {
  list-style-position: outside;
}

/* lists */
ul.text {
  li {
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px;
  }
}

/* list header */
.list-header {
  background: #f5f5f5;
  border: 1px solid #dedede;
  border-radius: 4px 4px 0 0;
  padding: 10px;
}
.list-header + .list {
  border-radius: 0 0 4px 4px;
  border-top: none;
}
.list-header + .list li:first-child {
  border-radius: 0;
}
.list-header > * {
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}
.list-header > .btn-bar,
.list-header > .btn-group {
  margin-right: 10px;
}
.list-header > :last-child {
  margin-right: 0;
}

/* horizontal list */
ul.horizontal {
  margin: 0;
}
ul.horizontal li {
  display: inline-block;
  margin: 0 10px 0 0;
}
.form ul.horizontal label {
  display: inline-block;
  margin: 0;
}

/* bulleted list */
ul.bullets {
  list-style: none;
  padding: 0;
  vertical-align: top;
}
ul.bullets li {
  background: transparent
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAABSklEQVR42pWRO0sDURSE8yBNwFIbu5T+DSvBzh9gkQ2SUsUqNlqpaDCCKGIWxFiliKhr5SOgBB9EUQK6WQO+soGkWNn4QJvxzMVbpLT4YJiZc/eeuwEA/yIQGxrThMy00Z/LxPNbS/E6oabHTPd0ObyxaMwWzHE82Wf49BtEaXrM2BGC6uS1eWNgbzOFr3YTP+8uvv1nQq08KzcJ6Qyyy4GInLDzeF+C36rCc286oMcvScdilwNRua/vNR/QcE5Qt487cMV7a9YgnQ92OdAly7VbrxU4V9uwL/Md0GPGAXbVwPqCcVCrFHF3kUf5aBXlwxVCrTxm7OiB6PTUyDCXfnHOcV00UdpPE2rlMWNHXyki9GRmEtlCdhTV21050SJK02PGjl46xEmhdyKVTC7PJU7lRTxCTY8ZO/pZg0L4z+gWYkIfoabHTP+4X1kgVgLT3WzuAAAAAElFTkSuQmCC)
    no-repeat 0 2px;
  list-style: none;
  margin-bottom: 10px;
  padding-left: 20px;
}
.module > ul.bullets:last-child {
  margin-bottom: 15px;
}

/* basic list */
ul.basic {
  clear: both;
  padding: 0;
  vertical-align: top;
}
ul.basic li {
  background: none;
  list-style: none;
  margin: 0 0 15px;
  padding: 0;
}

/* definition lists */
dl dt {
  font-weight: 700;
}
dl dd {
  margin: 0 0 10px;
}
dl.parallel {
  padding: 0 0 0 240px;
  @extend %clearfix;
}
dl.parallel dt {
  clear: left;
  display: block;
  float: left;
  margin: 0 0 10px -240px;
  text-align: right;
  width: 220px;
}
dl.parallel dd {
  min-height: 20px;
  float: left;
}

/* step by step lists */
ol.step-by-step {
  padding-right: 0;
  list-style-type: none;
  position: relative;

  li {
    counter-increment: step-counter;
    list-style-type: none;
    position: relative;
    margin: 0 0 20px;
    padding: 0 0 0 60px;
    min-height: 40px;
    h2 {
      font-weight: 600;
      margin-bottom: 5px;
      line-height: 1em;
    }
  }
  > li::before {
    position: absolute;
    left: 0;
    background: #f0f0f0;
    height: 40px;
    width: 40px;
    border-radius: 50%;

    content: counter(step-counter);
    font-size: 24px;
    color: $pacific;
    text-align: center;

    font-weight: 600;
    line-height: 40px;
  }
}
.step-by-step.guide {
  li {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
  }
}

/* Deprecated list*/
ul.deprecated-list {
  border: 1px solid $grey-40;
  clear: both;
  color: $black;
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
  position: relative;
}
ul.deprecated-list > li {
  background: #fbfbfb;
  border-bottom: 1px solid #ccc;
  list-style: none;
  margin: 0;
  padding: 10px 20px 9px;
  position: relative;
}
ul.deprecated-list > li:first-child {
  border-radius: 4px 4px 0 0;
}
ul.deprecated-list > li:last-child {
  border-radius: 0 0 4px 4px;
  border-bottom: none;
}
ul.deprecated-list > li:only-child {
  border-radius: 4px;
}
