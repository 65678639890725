.svg-sprite {
  display: inline-block;
  fill: $charcoal-60;
  height: 16px;
  width: 16px;
  vertical-align: middle;
  pointer-events: fill;
  -webkit-backface-visibility: hidden;

  &.xxsml {
    height: 8px;
    width: 8px;
  }
  &.xsml {
    height: 12px;
    width: 12px;
  }
  &.sml {
    height: 24px;
    width: 24px;
  }
  &.med {
    height: 32px;
    width: 32px;
  }
  &.lrg {
    height: 48px;
    width: 48px;
  }
  &.xlrg {
    height: 64px;
    width: 64px;
  }
  &.xxlrg {
    height: 128px;
    width: 128px;
  }
  &.big {
    height: 200px;
    width: 200px;
  }
  &.actionable {
    fill: $pacific;
    transition: fill 0.25s ease-in-out;
    &:hover {
      fill: lighten($pacific, 10%);
    }
  }
  &.disabled {
    fill: $charcoal-20;
    &:hover {
      fill: $charcoal-20;
    }
  }
  &.destructive {
    fill: $charcoal-40;
    transition: fill 0.25s ease-in-out;
    &:hover {
      fill: $delete;
    }
  }
  // Base colors
  &.pacific {
    fill: $pacific;
  }
  &.white {
    fill: $white;
  }
  &.hefe {
    fill: $hefe;
  }
  &.other {
    height: 40px;
    width: 40px;
  }
  // Fill colors
  &.-muted {
    fill: $charcoal-40;
  }
  &.-error {
    fill: $error-fill;
  }
  &.-warning {
    fill: $warning-fill;
  }
  &.-success {
    fill: $kermit-green;
  }
}
