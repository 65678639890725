.section-nav {
    font-size: 16px;
    position: relative;
    padding: 0;
    margin: 0 20px 0 0;
    list-style: none;
    @extend %clearfix;
}

.section-nav > li {
    display: block;
    float: left;
    float: left;
    font-size: 18px;
    font-weight: 600;
    line-height: 50px;
    position: relative;
    white-space: nowrap;
    min-width: 200px;
    padding: 0;
    margin: 0 2px 0 0;
    -webkit-transition: all 0.125s ease-in-out;
    transition: all 0.125s ease-in-out;

    &:after {
        background: $white;
        content: '';
        display: block;
        height: 0px;
        bottom: 0px;
        left: 0px;
        position: absolute;
        width: calc(100% - 2px);
        -webkit-transition: height 0.25s ease-in-out;
        transition: height 0.25s ease-in-out;
    }

    &.app-settings {
        width: 48px;
        min-width: 48px;
    }

    &:hover {
        &:after {
            background: $link-color;
            height: 4px;
        }
    }

    &.current {
        &:after {
            background: $pacific;
            height: 4px;
        }
        a.active:after {
            background: $charcoal-20;
            height: 4px;
            z-index: 10;
        }
    }
}

.section-nav > li > a {
    color: $pacific;
    display: block;
    padding: 5px 15px;
    text-align: left;
    font-weight: 400;
    border-left: 1px solid $white;
    border-top: 1px solid $white;
    border-right: 1px solid $white;

    &:after {
        background: $white;
        content: '';
        display: block;
        height: 0px;
        bottom: 0px;
        left: 0px;
        position: absolute;
        width: calc(100% - 2px);
        transition: height 0.25s ease-in-out;
    }

    .parent {
        padding: 0 5px;
        display: inline-block;
    }

    // Hover
    &:hover {
        text-decoration: none;
    }

    // Click Active State
    &.active {
        background: $white;
        border-left: 1px solid $charcoal-20;
        border-top: 1px solid $charcoal-20;
        border-right: 1px solid $charcoal-20;
        color: $pacific;
    }
}

.section-nav > li > ul.popover {
    left: 0px;
    top: 61px;
    min-width: 199px;
    list-style: none;
    padding: 0;
    border: none;
    border-radius: 0;
    background: $white;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);

    li {
        position: relative;

        a {
            font-size: 15px;
            font-weight: normal;
            padding: 5px 15px;
            line-height: 30px;
            background: $white;
            color: $charcoal-80;//$charcoal;
            margin: 0;
            transition: all 0.25s ease-in-out;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 0px;
                display: block;
                background: $white;
                transition: all 0.125s ease-in-out;
            }

            &:hover {
                color: $link-color;

                &:before {
                    width: 6px;
                    background: $charcoal-40;
                }
            }
        }

        &.current {
            a {
                color: $pacific;
                font-weight: 600;
                position: relative;

                &:before {
                    width: 6px;
                    background: $pacific;
                }
            }
        }
    }
}


.section-nav--parent-only {
    > li {
        min-width: 0;
    }
}
