/****** Tag & Device Pickers ******/
.tag-picker {
    width:320px;
}
.tag-picker em {
    color:#999;
    display:block;
    font-size:11px;
    margin:10px 10px 0;
}
.tag-picker .search,
.device-picker .search {
    position:relative;
}
.tag-picker label {
    color:#999;
    font-size:12px;
    margin:0;
}

/* Tag Group Picker */
.popover-content.tag-picker.tag-groups {
    padding:0;

    ul.tag-group-list {
        margin:0;
        padding:0;

        li {
            border-bottom:1px solid $grey-lt;
            list-style:none;
            margin:0;
            overflow:hidden;
            padding:12px 10px;
            transition:all 0.25s ease;

            &:last-of-type {
                border-bottom:0;
            }

            &:hover {
                background:$white;
                cursor:pointer;
            }
        }
    }

    .tag-group-meta-info {
        float:left;
        width:260px;
    }

    .tag-group-title {
        display:block;
        font-size:14px;
        font-weight:700;
        margin-bottom:5px;
    }

    .tag-group-title,
    .tag-group-description {
        width:260px;
        word-wrap:break-word;
        display:block;
        white-space:normal;
    }

    .nested-list-nav {
        padding:17px 0 0 0;

        &.explore {
            display:inline-block;
            float:right;
            width:20px;
        }

        &.back-out {
            float:left;
            width:20px;
        }
    }



    .selected-group {
        background:$white;
        overflow:hidden;
        padding:10px 10px 1px;

        .tg-title {
            margin-left:20px;
        }

        .tag-group-description {
            line-height:16px;
            margin-bottom:10px;
        }
        &:hover {
            cursor:pointer;
        }
    }
}

// OOTB Tags
.tag-groups.tag-picker .tag-sub-group {

    ul.sub-group-list {
        max-height: 250px;
        overflow-y: scroll;
        background: #f2f2f2;
        border-top: 1px solid #ccc;
        margin: 0;
        padding: 0;

        li {
            @extend %clearfix;
            background: #f2f2f2;
            display: block;
            list-style: none;
            margin: 0;
            transition: all .2s linear;
            -webkit-transition: all .2s linear;
            -moz-transition: all .2s linear;
            -ms-transition: all .2s linear;

            .tag-group-meta-info {
                @extend %clearfix;
                float: none;
                padding: 10px 15px;
            }

            &:hover {
                background: $white;
                cursor: pointer;

            }

            .results-container {
                height: auto;
                overflow: hidden;
                padding: 0 10px;
            }

            .tag-group-search {
                margin: 8px 0;
                padding: 0;

                ul.results {
                    margin: 15px 0 0 0;
                    li {
                        background: $white;
                        padding: 0;
                    }
                }
            }

            &.active {
                background: $white;
                margin: 10px 0 0 0;
            }

            .button-options {

                ul {
                    padding: 0;
                    margin: 0;

                    li {
                        box-sizing: border-box;
                        display: inline-block;
                        padding: 0;
                        text-align: center;
                        width: 50%;

                        a {
                            background: $white;
                            border: 1px solid #ccc;
                            display: block;
                            font-weight: 700;
                            padding: 10px 0;
                            text-transform: uppercase;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
}


/*hack to hide label on webkit browsers that have nice placeholders*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
    .tag-picker label {display:none;}
}

.tag-group-search {

    .search-form {
        background: #f6f6f6;
        padding:10px;
    }

    ul.results {
        margin: 0;

        > li {
            background-image:none;
            list-style-type:none;
            margin:0;
            padding:0;

            a:hover {
                background:#d1e3ee;
                background:-webkit-linear-gradient(top, #e5f5ff, #d1e3ee);
                background:linear-gradient(to bottom, #e5f5ff, #d1e3ee);
                border-bottom-color:#b8d3e5;
                border-top-color:#dae9f2;
                box-shadow:none;
                text-decoration:none;
            }
        }
    }
}
.tag-picker .search input.loading:before {
    content:'';
    position:absolute;
    top:7px;
    right:0;
}

.tag-picker .results-container {
    border-top: 1px solid #e6e6e6;
    height: 250px;
    overflow-y: scroll;
    padding: 0 10px 10px;
}

.tag-picker ul.results li:first-child {
    margin-top:15px;
}
.tag-picker ul.results li a {
    border-radius:4px;
    color:#333;
    display:block;
    font-size:15px;
    font-weight:normal;
    margin:0 0 5px;
    padding:7px 10px;
    position:relative;
    text-shadow:0 1px 0 #fff;
}
.tag-picker ul.results li.highlight a {
    background:#d1e3ee;
    border-bottom-color:#b8d3e5;
    border-top-color:#dae9f2;
    box-shadow:none;
    background:-webkit-linear-gradient(top, #e5f5ff, #d1e3ee);
    background:-moz-linear-gradient(top, #e5f5ff, #d1e3ee);
    background:linear-gradient(top, #e5f5ff, #d1e3ee);
}
.tag-group-search .loader {
    position:absolute;
    right:10px;
    top:6px;
}

// List Picker
.popover-content.list-picker {
    padding: 0;
    width: 320px;
}
.list-picker ul.nested-lists {

    li {
        border-bottom: 1px solid #e6e6e6;
        display: block;
        padding: 12px 10px;
        &:hover {
            background: $white;
            cursor: pointer;
        }
    }

    .nested-list-nav.explore {
        display: inline-block;
        float: right;
        margin-top: -25px;
        width: 20px;
    }

}
.list-meta-info {
    .list-title,
    .list-description {
        width: 280px;
    }
    .list-title {
        display: block;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px;
    }
}
.selected-group {
    background: $white;
    border-bottom: 1px solid #e6e6e6;
    display: block;
    padding: 12px 10px;

    .list-title {
        margin-bottom: 0;
    }

    .nested-list-nav.back-out {
        display: inline-block;
        float: left;
        margin-top: 15px;
        text-align: center;
        width: 30px;
    }
}
// Lifecycle List of List Variations
ul.list-parent-groups {
    margin: 0;
    padding: 0;

    li {
        display: block;
        list-style-type: none;
        margin: 0;
        padding: 0;

        .list-meta {
            background: #666;
            color: $white;
            padding: 12px 10px;

            .list-title {
                font-weight: 700;
            }
        }
    }
}

ul.list-parent-groups li ul.list-period-variations {
    background: #f2f2f2;
    padding: 3px;

    li {
        border: 1px solid #f2f2f2;
        padding: 12px 10px;
        margin-bottom: 2px;

        &:hover {
            background: #d1e3ee;
            background: -webkit-linear-gradient(top, #e5f5ff, #d1e3ee);
            background: linear-gradient(to bottom, #e5f5ff, #d1e3ee);
            border: 1px solid #dae9f2;
            cursor: pointer;
        }

        .list-period-name {
            color: #21a1d9;
            font-weight: 700;
        }

        .list-period-count {
            float: right;
        }
    }

    li.list-unavailable {
        .list-period-name,
        .list-period-count {
            color: #444;
            opacity: 0.6;
        }

        &:hover {
            cursor: default;
            background: #f2f2f2;
            border: 1px solid #f2f2f2;
        }
    }
}

// Uploaded List Search
.list-picker .search {
    padding: 6px;
}
.list-picker ul.results {
    background: #f2f2f2;
    padding: 0 3px;
    margin-bottom: 0;

    li {
        border: 1px solid #f2f2f2;
        list-style: none;
        padding: 8px;
        margin: 0;

        &:hover {
            background: #d1e3ee;
            background: -webkit-linear-gradient(top, #e5f5ff, #d1e3ee);
            background: linear-gradient(to bottom, #e5f5ff, #d1e3ee);
            border: 1px solid #dae9f2;
        }

        .list-name {
            color: #333;
            display: block;
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 3px;
        }

        a:hover {
            text-decoration: none;
        }
    }
}

/* Create tag popover */
.btn.create-tag {
    display:none;
 }
.has-text .btn.create-tag {
    display:block;
    margin:10px 0 0;
    text-align:center;
}

.location-popover-content {
    @extend .tag-picker;
}

.location-popover {
    margin-top:10px;
}

.location-popover .loader {
    position:absolute;
    right:8px;
    top:8px;
}

.location-popover .search-results {
    max-height:300px;
    overflow:auto;
}

.compass .back {
  fill: #eee;
  fill-opacity: .8;
}

.compass .fore {
    stroke: #999;
    stroke-width: 1.5px;
}

.compass rect.back.fore {
    fill: #999;
    fill-opacity: .3;
    stroke: #eee;
    stroke-width: 1px;
    shape-rendering: crispEdges;
}

.compass .direction {
fill: none;
}

.compass .chevron {
    fill: none;
    stroke: #999;
    stroke-width: 5px;
}

.compass .zoom .chevron {
    stroke-width: 4px;
}

.compass .active .chevron, .compass .chevron.active {
    stroke: #fff;
}

.compass.active .active .direction {
    fill: #999;
}

/* Preditctive Tag Styles */
li.high, .medium, .low {
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  list-style: none;

/* Gives link a little more target area */
  a {
    display: block;
    padding: 10px;
  }
}

/* Janky fix for extra padding at bottom */
.button-options {
  ul {
    margin: 0;
  }
}

.auto-complete {
    width: 100%;
    position: relative;
    &.has-results {
        height: 160px;
        border: 1px solid $charcoal-40;
        box-shadow: rgba(0,0,0,0.2) 0 1px 3px;
        border-radius: 6px;
        background: $white;
        input {
            border: none;
            border-radius: 6px 6px 0 0;
        }
    }
    .results {
        position: absolute;
        border-top: 1px solid $charcoal-20;
        left: 0;
        right: 0;
        overflow-y: scroll;
        max-height: 118px;
        padding-top: 6px;
        li {
            margin: 0;
            line-height: 30px;
            padding: 0 10px;
            &:hover {
                background: $charcoal-20;
            }
            a {
                display: block;
            }
        }
    }
}
