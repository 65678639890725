.date-picker {
    width:200px;
    background:#FFF;
    border:1px solid #CCC;
    padding:10px;
    border-radius:4px;
}
.date-picker h3 {
    text-align:center;
    font-size:12px;
    margin:0 0 5px 0;
    padding:0;
    width:100%;
}
.date-picker h3 a.pager {
    font-size:18px;
    line-height:12px;
}
.date-picker h3 a.next {
    float:right;
}
.date-picker h3 a.prev {
    float:left;
}
.date-picker table {
    width:100%;
    background:#DCE9F1;
    margin:0;
}
.date-picker table th {
    background:#336781;
    color:#FFF;
    border:1px solid #336781;
    border-bottom:1px solid #DCE9F1;
    box-shadow:none;
    padding:0;
    text-align:center;
    font-size:inherit;
}
.date-picker table td {
    vertical-align:middle;
    text-align:center;
    width:20px;
    height:20px;
    border:1px solid #BDD0DC;
    padding:0;
}
.date-picker table td:hover {
    background:#FFF;
    color:#000;
    cursor:pointer;
}
.date-picker table td.selected {
    background:#346883;
    color:#FFF;
}

/****** Date Range Picker ******/
.date-range-picker {
    width:460px;
}
.date-range-picker .date-granularity {
    background:#021b26;
    border-bottom:#023951 1px solid;
    border-radius:0;
    color:#e5fdff;
    font-weight:700;
    margin:-10px -10px 0 -10px;
    padding:4px 10px 0px;
}
.date-range-picker .date-granularity > .btn-bar {
    display:inline-block;
    margin-left:10px;
    vertical-align:middle;
}
.date-range-picker .date-pick {
    padding:10px 0 0;
    @extend %clearfix;
}
.date-range-picker .date-pick[name="end"] {
    border-top:1px solid #d9d9d9;
    box-shadow:inset 0 1px 0 0 #fff;
}
.date-range-picker .date-pick > div {
    margin:0 0 10px 200px;
    vertical-align:top;
}
.date-range-picker .date-pick > div:first-child {
    border:none;
    box-shadow:none;
    float:left;
    margin-left:0;
}
.date-range-picker .date-pick label {
    font-size:12px;
    margin:0;
}
.date-range-picker .date-pick input {
    font-weight:bold;
    width:238px;
}
.date-range-picker .date-pick input[type="checkbox"] {
    width:auto;
}
.date-range-picker .date-pick p.description {
    font-size:12px;
    margin-left:18px;
}
.date-range-picker .date-pick select {
    background-position:92% 50%;
    width:60px;
}
.date-range-picker .date-pick select:last-child {
    margin-left:5px;
}
.date-range-picker .date-pick small {
    color:#999;
    padding:0 1px;
}
.date-range-picker .date-pick .date-cal {
    position:relative;
}
.date-range-picker .date-pick .date-cal > a[rel="prev"],
.date-range-picker .date-pick .date-cal > a[rel="next"] {
    background:transparent url(uiurl('images/icons.png')) no-repeat 0 -100px;
    display:block;
    height:20px;
    margin:8px 0 0 15px;
    position:absolute;
    width:20px;
}
.date-range-picker .date-pick .date-cal > a[rel="next"] {
    background-position:-25px -100px;
    margin:8px 0 0 0;
    right:0;
}
.date-selector > a[rel="prev"],
.date-selector > a[rel="next"] {
    background: transparent url(uiurl('images/sprites.png')) no-repeat -23px -148px;
    display: block;
    height: 20px;
    margin: 8px 0 0 20px;
    position: absolute;
    width: 20px;
}
.date-selector > a[rel="next"] {
    background-position: -50px -148px;
    margin: 8px 24px 0 0;
    right: 0;
}
.date-range-picker .date-pick .date-cal table {
    box-shadow:0px 2px 3px 0 rgba(0,0,0,.15);
    margin:0;
}
.date-range-picker .date-pick .date-cal table th span {
    background:#7c858b;
    background:-webkit-gradient(linear, left top, left bottom, from(#7e878c), to(#566773));
    background:-moz-linear-gradient(top, #7e878c, #566773);
    border:1px solid #393d40;
    border-radius:4px 4px 0 0;
    box-shadow:inset 0 1px 0 0 rgba(255,255,255,.2);
    color:#fff;
    display:block;
    height:25px;
    line-height:25px;
    margin-right:-1px;
    text-shadow:0 -1px 0 rgba(0,0,0,.25);
    text-align: center;
}
.date-range-picker .date-pick .date-cal table td {
    border-bottom:1px solid #98a8b3;
    border-right:1px solid #98a8b3;
    color:#333;
    height:25px;
    line-height:25px;
    width:25px;
    text-align:center;
}
.date-range-picker .date-pick .date-cal table td:first-child {
    border-left:1px solid #98a8b3;
}
.date-range-picker .date-pick .date-cal table td a {
    box-shadow:inset 0 1px 0 0 rgba(255,255,255,.6);
    color:#333;
    display:block;
    font-weight:bold;
    text-shadow:0 1px 0 #fff;
}
.date-range-picker .date-pick .date-cal table td a:hover {
    background-color:#fff;
    text-decoration:none;
}
.date-range-picker .date-pick .date-cal table td.inactive {
    background:#e6e6e6;
}
.date-range-picker .date-pick .date-cal table td.inactive a {
    color:#999;
}
.date-range-picker .date-pick .date-cal table td.date {
    background:#e8f3fa;
}
.date-range-picker .date-pick .date-cal table td.selected {
    border-bottom-color:#001f33;
    border-right-color:#004c80;
}
.date-range-picker .date-pick .date-cal table td.selected a {
    background:#0062a3;
    background:-webkit-gradient(linear, left top, left bottom, from(#0062a3), to(#004c80));
    background:-moz-linear-gradient(top, #0062a3, #004c80);
    border:1px solid #004c80;
    box-shadow:inset 0 1px 0 0 rgba(255,255,255,.15);
    color:#fff;
    margin:-1px;
    position:relative;
    text-shadow:0 -1px #001f33;
    width:25px;
}
.date-range-picker > div:last-child {
    border-top:1px solid #d9d9d9;
    box-shadow:inset 0 1px 0 0 #fff;
    clear:both;
    padding-top:10px;
}

/* Disaled dates */
.date-range-picker .date-pick .date-cal table td.date.disabled.selected,
.date-range-picker .date-pick .date-cal table td.date.disabled.selected:hover a,
.date-range-picker .date-pick .date-cal table td.date.disabled.selected a,
.date-range-picker .date-pick .date-cal table td.date.disabled.selected a:hover {
    background:#ffd11a;
    background:#ffd11a -webkit-linear-gradient(-45deg, rgba(0,0,0,.2) 25%, transparent 25%, transparent 50%, rgba(0,0,0,.2) 50%, rgba(0,0,0,.2) 75%, transparent 75%, transparent);
    background:#ffd11a linear-gradient(-45deg, rgba(0,0,0,.2) 25%, transparent 25%, transparent 50%, rgba(0,0,0,.2) 50%, rgba(0,0,0,.2) 75%, transparent 75%, transparent);
    cursor:default;
}
.date-range-picker .date-pick .date-cal table td.disabled.selected,
.date-range-picker .date-pick .date-cal table td.disabled.selected:hover,
.date-range-picker .date-pick .date-cal table td.disabled.selected a:hover,
.date-range-picker .date-pick .date-cal table td.disabled.inactive:hover,
.date-range-picker .date-pick .date-cal table td.disabled.inactive,
.date-range-picker .date-pick .date-cal table td.disabled.inactive a:hover,
.date-range-picker .date-pick .date-cal table td.date.disabled:hover,
.date-range-picker .date-pick .date-cal table td.date.disabled,
.date-range-picker .date-pick .date-cal table td.date.disabled a:hover {
    background:#bac2ca;
    background:#e8f3fa -webkit-linear-gradient(-45deg, rgba(0,0,0,.2) 25%, transparent 25%, transparent 50%, rgba(0,0,0,.2) 50%, rgba(0,0,0,.2) 75%, transparent 75%, transparent);
    background:#e8f3fa linear-gradient(-45deg, rgba(0,0,0,.2) 25%, transparent 25%, transparent 50%, rgba(0,0,0,.2) 50%, rgba(0,0,0,.2) 75%, transparent 75%, transparent);
    cursor:default;
}

/* Standalone date picker */
.date-range-picker.date-selection {
    width:auto;
}
.date-range-picker.date-selection > .date-pick {
    border:none;
    box-shadow:none;
    padding:0;
}
.date-range-picker.date-selection > div:last-child {
    border-top:none;
    box-shadow:none;
    clear:both;
    padding:0;
}
.date-range-picker.date-selection .date-pick > div {
    margin:0;
}
.date-range-picker.date-selection .date-cal {
    text-align:center;
}

/* Date range */
.date-range {
    padding:0 10px;
}
.date-range [name="start"]:hover,
.date-range [name="end"]:hover {
    cursor:pointer;
}
.date-range {
    display:none;
    opacity:0;
    -webkit-transition:all .4s linear;
    transition:all .4s linear;
}
.date-range.always-show {
        display: inline-block;
        opacity: 1;
}
.date-range.show {
    display:inline-block;
    opacity:1;
}
#settings select[name="precision"] {
    bottom:15px;
    display:none;
    position:absolute;
    right:0;
}

/* Date picker */
.time-series-selection {
  display: inline-block;
  position: relative;
}
.time-series-selection .popover {
  min-width: 120px;
  position: absolute;
  z-index: 10;
  right: 0;
}

/* Goatzart date slection for airship usage */
// Pick a Day
.delivery-date {
    position: relative;
}
.date-selector {
    background: $white;
    border-radius: 3px;
    box-shadow: rgba(0,0,0,.4) 0 1px 3px;
    left: 0;
    padding: 10px;
    position: absolute;
    top: 65px;
    z-index: 9999;
    -webkit-transition: all 2.5s ease-in-out;
    transition: all 2.5s ease-in-out;
    header {
        display: table;
        margin: 0 0 10px;
        table-layout: fixed;
        width: 100%;
        > div {
            display: table-cell;
            vertical-align: middle;
            &:last-child {
                text-align: right;
            }
        }
        .date_year {
            display: block;
            font-size: 14px;
            line-height: 20px;
            margin: 0;
            text-align: center;
        }
        .date_month {
            display: block;
            font-size: 16px;
            font-weight: 700;
            line-height: 1;
            letter-spacing: 1px;
            margin: 0;
            text-align: center;
            text-transform: uppercase;
        }
    }
}
.date-selector table {
    background: $white;
    margin: 0;
    width: 100%;
    thead {
        td {
            background:  $charcoal-20;
            font-size: 12px;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
        }
        &:after {
            content: "";
            display: block;
            height: 10px;
        }
    }
    tbody {
        td {
            border: transparent 1px solid;
            border-radius: 20px;
            color: $charcoal-60;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            height: 30px;
            width: 30px;
            text-align: center;
            vertical-align: middle;
            &:hover {
                background: #fafafa;
                cursor: pointer;
            }
            &.selected {
                background: $pacific;
                color: $white;
                a {
                    color: $white;
                }
                &.disabled {
                    background: $pacific;
                    border-radius: 0;
                    color: $white;
                    &:hover,
                    a {
                        background: $pacific;
                        cursor: default;
                    }
                }
            }
            &.disabled {
                opacity: .2;
                &:hover,
                a {
                    background: none;
                    cursor: default;
                }
            }
        }
    }
}
.delivery-time {
    font-size: x-small;
    width: 545px;
    select {
        display: inline-block;
        margin: 0 5px 0 0;
        width: 65px;
    }
    span {
        margin: 0 5px 0 0;
    }
    .time {
        display: inline-block;
    }
    .zone {
        display: inline-block;
        select {
            width: 300px;
        }
    }
}
.current-zone {
    color: $charcoal-40;
    font-size: 13px;
}
