/* Old tabs */
.tabber {
    @extend %clearfix;
}
.tabbar a {
    background:#f2f2f2;
    background:-webkit-linear-gradient(top, #f5f5f5, #e9e9e9);
    background:-moz-linear-gradient(top, #f5f5f5, #e9e9e9);
    background:linear-gradient(top, #f5f5f5, #e9e9e9);
    border:1px solid #d9d9d9;
    border-bottom:none;
    border-radius:4px 4px 0 0;
    box-shadow:inset 0 1px 0 rgba(255,255,255,.2);
    color:#346883;
    display:inline-block;
    float:left;
    font-weight:bold;
    margin-left:5px;
    min-height:20px;
    padding:6px 10px 3px;
    text-decoration:none;
    text-shadow:0px 1px 0 rgba(255,255,255,.8);
    vertical-align:bottom;
    width:auto;
}
.tabbar a.on {
    background:#1d5073;
    background:-webkit-linear-gradient(top, #33739e, #1d5173);
    background:-moz-linear-gradient(top, #33739e, #1d5173);
    background:linear-gradient(top, #33739e, #1d5173);
    border-color:#396380;
    color:#fff;
    text-shadow:0px -1px 0 rgba(29,64,87,.4);
}
#tab-content fieldset {
    padding:0;
}

/* New tabs */
.tabs {
    box-shadow:#dbdbdb 0 -1px 0 inset;
    display:table;
    list-style:none;
    margin:0 -20px 20px;
    padding:0 20px;
    table-layout:fixed;
    width:100%;

    > li {
        display:table-cell;
        position:relative;

        a,
        label {
            background:#fff;
            border:#dbdbdb 1px solid;
            border-radius:4px 4px 0 0;
            color:$link-color;
            cursor:pointer;
            display:block;
            font-size:14px;
            -webkit-font-smoothing:antialiased;
            font-weight:bold;
            line-height:20px;
            margin:0 0 0 10px;
            overflow:hidden;
            position:relative;
            padding:5px 10px;
            text-align:center;
            text-overflow:ellipsis;
            white-space:nowrap;

            &:hover,
            &:focus {
                background:#fff;
                text-decoration:none;
            }
        }

        &:first-child a,
        &:first-child label {
            margin-left:0;
        }

        &.active a,
        [type="radio"]:checked + label {
            background:#fff;
            border-bottom:#fff 1px solid;
            color:$charcoal;
            cursor:default;
        }
    }
}


@media only screen and (max-width:940px) {
    .tabbar a {
        border:1px solid #d9d9d9;
        border-radius:4px;
        margin:0 5px 5px 0;
    }
}