@import 'settings';

.module p.loading {
  color: $white;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  text-shadow: rgba(255, 255, 255, 0.8) 0 1px 0;
  vertical-align: middle;
}
.spinner {
  display: none; /* Hide unless it's loading */
}
.loading .loader {
  display: block;
}
.loading .spinner {
  background-image: url(uiurl('images/spinner.png'));
  display: inline-block;
  font-size: 0;
  height: 20px;
  width: 20px;
  vertical-align: middle;
  -webkit-animation: spinner 0.5s steps(8) infinite;
  animation: spinner 0.5s steps(8) infinite;
}
@-webkit-keyframes spinner {
  0% {
    background-position: 0px;
  }
  100% {
    background-position: -160px;
  }
}
@keyframes spinner {
  0% {
    background-position: 0px;
  }
  100% {
    background-position: -160px;
  }
}
.loading-section {
  box-sizing: content-box;
  background: $grey-80;
  margin: -60px 0 0 -130px;
  padding: 30px;
  position: fixed;
  text-align: center;
  height: 60px;
  width: 200px;
  top: 50%;
  left: 50%;
  z-index: 10000;
  .loading {
    font-size: 18px;
    color: $white;
    margin: 0;
    .spinner {
      display: block;
      margin: 0 auto 15px;
    }
  }
}

/* Loading mask - parent sections needs overflow hidden */
.loading-mask {
  background: rgba(255, 255, 255, 0.8);
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
}
.loading-mask .spinner {
  left: 50%;
  margin: -10px 0 0 -10px;
  position: absolute;
  top: 50%;
}
.loading-mask .loading-message {
  color: $white;
  display: block;
  font-weight: 700;
  left: 50%;
  margin: 0 0 0 -100px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 200px;
}
.loading-mask .loading-message .spinner {
  margin: 0;
  position: static;
}
.loading .loading-mask {
  display: block;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .loading .spinner {
    background-image: url(uiurl('images/spinner@2x.png'));
    background-size: 160px 20px;
  }
}
