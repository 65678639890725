.module {
  background: #fff;
  border: #eceded 1px solid;
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 2px;
  clear: both;
  color: #444;
  margin-bottom: 20px;
  padding: 15px 20px 0;
  position: relative;
  @extend %clearfix;
}
.module.login {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
  padding: 20px;

  h1 {
    border-bottom: 1px solid lighten($grey-lt, 10%);
    box-shadow: 0 8px 6px -6px lighten($grey-lt, 5%);
    color: #015172;
    font-size: 40px;
    font-weight: 300;
    line-height: 60px;
    margin-bottom: 40px;
    padding: 0 0 10px;
    position: relative;
    text-align: center;
  }
}
.module.loading {
  min-height: 40px;
  text-align: center;
}
.module.empty {
  padding: 20px;
  text-align: center;
}
.module.empty p {
  margin: 0;
}
.module-header {
  background: #f5f5f5;
  border-bottom: #eceded 1px solid;
  border-radius: 4px 4px 0 0;
  box-shadow: rgba(255, 255, 255, 0.8) 0 1px 0 inset;
  line-height: 20px;
  margin: -15px -20px 15px;
  padding: 5px 10px;
  text-shadow: rgba(255, 255, 255, 0.8) 0 1px 0;
  @extend %clearfix;
}
.module-header:first-child + .col {
  margin-left: 0;
}
.module-header:last-child {
  border-radius: 4px;
  border: none;
  margin-bottom: 0;
}
.module-header h3 {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}
ul.module li {
  list-style: none;
}
.module-nav,
ul.list.module-nav {
  position: absolute;
  right: -10px;
  top: 20px;
  width: 160px;
}

/* Module footer */
.module-footer {
  background: #f0f0f0;
  border-top: #eceded 1px solid;
  border-radius: 0 0 4px 4px;
  box-shadow: rgba(255, 255, 255, 0.8) 0 1px 0 inset;
  line-height: 20px;
  margin: 0 -20px;
  padding: 5px 10px;
  @extend %clearfix;
}
.module-footer p {
  font-size: 12px;
  line-height: 20px;
  margin: 0;
}
.module-footer.form-actions {
  padding: 10px;
}
.module-header + .module-footer {
  margin-top: -16px; /* collapse margin and border */
}

/* Reset margins in a module */
.module .module-list,
.module .accord-list {
  margin: -15px -20px 0;
}

/* Module list */
.module-list > li {
  background: #fbfbfb;
  background-image: -webkit-linear-gradient(top, #fbfbfb, #f5f5f5);
  background-image: -moz-linear-gradient(to bottom, #fbfbfb, #f5f5f5);
  border-bottom: #ddd 1px solid;
  box-shadow: rgba(255, 255, 255, 1) 0 1px 0 inset;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 10px;

  input[type='checkbox'],
  input[type='radio'] {
    margin-top: 2px;
  }
}
.module-list > li:last-child {
  border-bottom: 0;
}
.module-list > li > a {
  color: #21a1d9;
  font-weight: bold;
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
  text-decoration: none;
  text-shadow: rgba(255, 255, 255, 0.8) 0 1px 0;
}
.module-list > li > a:hover,
.module-list > li > a:active {
  background: rgba(33, 161, 217, 0.05);
}

/* Border radius management */
.module-list:first-child > li:first-child {
  border-radius: 4px 4px 0 0;
}
.module-list > li:last-child {
  border-radius: 0 0 4px 4px;
}
.module-list:only-child > li:only-child {
  border-radius: 4px;
}
.module-list:nth-last-child(2) > li:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
