.error-console {
    background: $charcoal;
    color: $white-smoke;
    display: none;
    height: 0; /* Hide by default */
    overflow: auto;
    h2 {
        color: $charcoal-60;
    }
}
.error-console .sub-header {
    margin:0;
}
.error-console table {
    width:100%;
}
.error-console tbody tr {
    border-bottom:#232323 1px solid;
}
.error-console tbody tr:first-child {
    border-top:#232323 1px solid;
}
.error-console td {
    padding:20px 10px;
}
.error-console .error-type {
    font-family:Monaco, "Lucida Console", monospace;
    text-align:center;
    width:10%;
}
.error-item .error-type {
    border-left:#dd151e 5px solid;
}
.warning-item .error-type {
    border-left:#e9d85a 5px solid;
}
.error-console .description {
    width:70%;
}
.error-console .description h4 {
    font-size:12px;
    font-weight:normal;
    margin:0;
}
.error-console .description p {
    color:#e0e0dc;
    font-size:12px;
    margin:0;
}
.error-item h4 {
    color:#e85662;
}
.warning-item h4 {
    color:#eddd5f;
}
.error-console .occurred {
    color:#949491;
    font-size:11px;
    text-align:right;
    text-shadow:0 -1px 0 rgba(0,0,0,.25);
    width:20%;
}
p.no-errors {
    color:#e0e0dc;
    font-size:15px;
    line-height:25px;
    margin:100px 0 0;
    text-align:center;
}
