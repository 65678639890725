.content {
  padding: 15px 0 0;
}
.main {
  min-height: calc(100% - 120px);
  margin: 0 auto;
  box-sizing: border-box;
  @extend %clearfix;
}

.logged-out {
  .main {
    position: relative;
    margin: 0 auto;
    padding: 60px 20px;
  }
}

// Remove margin-top in Engage
.product-engage .main {
  margin-top: 0;
}

// LEGACY
#content {
  padding: 0 10px;
}

@media only screen and (min-width: 940px) {
  .logged-out {
    .main {
      max-width: 1020px;
    }
  }
}

@media only screen and (max-width: 1620px) {
}
