
// Switch controlled by checkbox
.switch-check {
    .switch {
        background: $charcoal-20;
        cursor: pointer;
        min-height: 18px;
        vertical-align: middle;
    }
    input[type=checkbox]:checked + .switch {
        background: #1cb864;
        border-color: #1cb864;
        span {
            right: 0;
            -webkit-transition: right .175s linear;
            transition: right .175s linear;
        }
    }
}
