.app-navigator {
  display: flex;
  align-items: center;
  &__name {
    font-size: $font-size-sm;
    color: $black;
    max-width: 16rem;
    height: 1rem;
  }
  &__meta {
    color: $grey;
    font-size: $font-size-xs;
    letter-spacing: 1px;
    margin: 0;
    text-transform: uppercase;
  }
  &:hover {
    text-decoration: none;
  }
  &__type {
    fill: var(--blue);
    height: 30px;
    width: 30px;
    margin-right: 0.64rem;
    position: relative;
  }
}
.app-toggle {
  svg {
    fill: $black;
    &:hover {
      fill: $grey-40;
    }
  }
}

.app-search {
  border-radius: 20px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  &:hover {
    background: $grey-40;
    svg {
      fill: var(--blue);
    }
  }
}
.project-icon {
  background: var(--blue);
  color: var(--white);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
}
.project-img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: inline-flex;
}
.wallet-svg svg {
  width: 40px;
  height: 40px;
}
.connect-flight {
  min-width: 8rem;
}
