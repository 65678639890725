/* Typography */

/**
 * Modular Scale
 *
 * Define a modular scale for a consistent rhythm in
 * sizing and spacing of elements
 *
 * http://www.modularscale.com/?1&em&1.25&web&text
 */

$ms-base: 1rem;
// $ms-ratio: $major-second;

$font-size-xs: 0.64rem;
$font-size-sm: 0.8rem;
$font-size-md: 1.563rem;
$font-size-lg: 2.441rem;
$font-size-xl: 3.052rem;

$font-family-default: Inter, Helvetica, Arial, sans-serif;
$font-family-mono: Consolas, 'Lucida Console', 'Liberation Mono', 'Courier New',
  monospace, sans-serif;

$font-weight-light: 100;
$font-weight-normal: 300;
$font-weight-semibold: 500;
$font-weight-bold: 700;

$line-height-xs: 1.563rem;
$line-height-sm: 1.953rem;
$line-height-md: 2.441rem;
$line-height-lg: 3.052rem;
$line-height-xl: 3.815rem;

$heading-font: $font-weight-bold $font-size-lg $font-family-default;
$code-font: $font-size-sm $font-family-mono;

/**
 * Colors
 *
 * Where possible, color names should map to standard webcolors.
 * See: https://zaim.github.io/webcolors/
 *
 * For adjustments to existing colors (lightening, darkening) use the
 * `color` function: http://sass-lang.com/documentation/Sass/Script/Functions.html
 */

// Neutral palette

$black: #020202;
$white: #fff;

// Brand/Primary palette

$blue: #1657e5;
$dark-blue: #000080;
$teal: #14d0ff;
$red: #ff0d49;
$navy: #040444;

// Secondary palette

$green: #00b306;
$yellow: #ffb700;
$orange: #f22700;

$lime: #a3eb52; // deprecate
$pink: #eb5271; // deprecate
$purple: #c556f5; // deprecate

/* Shade Variables */
$grey-20: #f4f4f4;
$grey-40: #eae9e9;
$grey-50: #bcbdc2;
$grey-60: #a3a4a8;
$grey-70: #898b93;
$grey-80: #7b7c84;
$grey: #63656b;

$blue-20: lighten($blue, 65%); // deprecate
$blue-40: lighten($blue, 50%); // deprecate
$blue-60: lighten($blue, 30%); // deprecate
$blue-80: lighten($blue, 10%); // deprecate

/**
 * Color Placeholders
 *
 * These are used as defaults in place of actual colors.
 *
 * 1. Represents an "absense" of color
 * 2. Represents a current or inherited color
 */

$color-null: transparent; /* 1 */
$color-relative: currentColor; /* 2 */

/* Opacity */

$opacity-lower: 0.125;
$opacity-low: 0.25;
$opacity-mid: 0.5;
$opacity-high: 0.75;

/* Shades */

$shade-lower: rgba($black, 0.125);
$shade-low: rgba($black, 0.25);
$shade-mid: rgba($black, 0.5);
$shade-high: rgba($black, 0.75);

// depreciated
$pad: 15px;
$space: 30px;

/* Spacing */

$space-xs: 0.512rem;
$space-sm: 0.64rem;
$space-md: 0.8rem;
$space-lg: 1.25rem;
$space-xl: 1.563rem;
$space-xxl: 3.052rem;

/* Content */

$content-dash-em: '—';
$content-single-quote-open: '‘';
$content-single-quote-close: '’';
$content-double-quote-open: '“';
$content-double-quote-close: '”';

/* Base */

$base-background: $white;
$base-color: $black;
$base-margin: $space-md;

/* Links */

$link-color: $blue;
$link-hover-color: lighten($blue, 10%);

/* Lists */

$list-style-default: disc;
$list-style-numeric: decimal;
$list-marker-padding: $space-lg;

/* Motion */

$motion-duration-sm: 100ms;
$motion-duration-md: 180ms;
$motion-duration-lg: 300ms;
$motion-timing-fuction-default: ease;

// depriciated
$ease-smooth: cubic-bezier(0.23, 0.61, 0.41, 1);

/* Border */

$border-width-sm: 1px;
$border-width-md: 2px;
$border-width-lg: 3px;

$border-radius-xs: 2px;
$border-radius-sm: 3px;
$border-radius-md: 6px;
$border-radius-lg: 20px;
$border-radius-circle: 50%;

/* Rules */

$rule-color: $grey-20;
$rule-style: solid;
$rule-width-sm: $border-width-sm;
$rule-width-md: $border-width-md;

/* Shadow */
$shadow-sm: rgba(0, 0, 0, 0.2) 0 1px 3px;
$shadow-md: rgba(0, 0, 0, 0.2) 0 1px 3px;
$shadow-lg: rgba(0, 0, 0, 0.1) 0 4px 12px;

/* Component */

$control-stroke: $border-width-md;
$control-line: $border-width-sm;
$control-radius: $border-radius-md;
$control-background-color: $color-null;
$control-border-color: $color-relative;
$control-disabled-opacity: $opacity-mid;

/* Breakpoints */

$xs: (
  max-width: 25rem,
); // < 400px
$sm: 25rem; //400px
$smd: 37.5rem; //600px
$md-max: (
  max-width: 43.75rem,
); // < 700px
$md: 43.75rem; //700px
$lg: 61.25rem; //980px
$xl: 81.25rem; //1300px

/* OLD - left these in so build doesn't choke */

$body: #444;
// $black: #000000;
// $white: #fff;
$white-smoke: #f5f5f5;
$grey-lt: #e6e6e6;
// $grey: #ccc;
$grey-darkest: #444;
$grey-dk: #666;
// $navy: #025172;
// $blue: #015172;
$blue-dk: #021b26;
$blue-lt: #21a1d9;
// $red: #982440;
$tan: #fafaf7;
$tan-dk: #f0f0ed;
$fog: #fbfbfb;
$granite: #263438;
$slate: #778c92;

// Brand colors
$charcoal: #3d494c;
$pacific: #1657e5; // New blue
$hefe: #ffc727;
$honey-wheat: #eaaa00;

// Brand accents
$charcoal-20: #eceded;
$charcoal-40: #b2b7b8;
$charcoal-60: #8b9294;
$charcoal-80: #646e70;

$pacific-20: #cce1e9;
$pacific-40: #99c3d2;
$pacific-60: #66a5bc;
$pacific-80: #3387a5;

$kermit-green: #1cb864;

$error: #edb8b6;
$error-fill: #d14e49;

$delete: $error-fill;

$selection-background: #003a54;
$selection-foreground: #fff;

// A very important class
.hidden {
  display: none;
}
