/* Performance Metrics */
.metrics {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 45px;
}
.metric {
    width: 20%;
    display: inline-block;
    box-sizing: border-box;
    padding: 0px 10px 10px 0;
}
.metric .metric__label {
    padding: 0 20px 30px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: $charcoal;
    background: $white;
    border-bottom: 1px solid #f1f1f1;
    border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
}
.metric .metric__values {
    display: block;
    padding: 30px 20px 0;
    box-sizing: border-box;
    background: $white;
    border-top: 1px solid #f1f1f1;
    border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
}
.metric .metric__values .value--p1 {
    display: block;
    width: 100%;
    font-size: 36px;
    font-weight: 500;
}

.metrics.metrics--message-table {
    background: $fog;
    box-shadow: 0px 1px 30px 0 rgba(0,0,0,0.1);
    margin-bottom: 0;
    .metric {
        border-top: 0;
        border-left: 0;
        text-align: left;
        padding: 0;
    }
    .metric .metric__values {
        display: block;
        background: transparent;
        height: 60px;
        border-top: 0;
        border-left: 0;
    }
    .metric .metric__label {
        background: transparent;
        border-left: 0;
    }
    .metric .metric__label:after {
        height: 26px;
        bottom: 0;
        top: auto;
        border-right: none;
    }
    /* Priority One Styling */
    .metric .metric__values .value--p1 {
        font-size: 26px;
    }
}
