.app {
    border-bottom: $blue 1px solid;
    display:block;
    padding:10px;
    position:relative;
    text-decoration:none;

    .app-meta {
        padding: 10px;
        h3 {
            color: $charcoal-80;
            margin: 0;
        }
        p {
            line-height: 1;
            margin: 0;
        }
    }
}
a.app:hover {text-decoration:none;}


.app--active {
    .app-meta {
        border-bottom: 1px solid $charcoal-20;
    }
}

.app-meta__app-icon {
    background-color: $teal;
    background-image: url(uiurl('images/default-app-icon.svg'));
    background-size: 48px;
    background-position: center center;
    background-repeat: no-repeat;
    height: 62px;
    overflow:hidden;
    position:relative;
    width: 62px;
    float: left;
    &.-custom {
        background-color: $white;
        background-size: cover;
    }
}
.app.module {
    border-radius: 2px;
    border: none;

}
.app-meta__app-icon--med {
    background-position:50%;
    background-size:50px;
    height:50px;
    width:50px;
}
.app-meta__app-icon--small {
    background-size:20px 20px;
    border-radius:4px;
    display:inline-block;
    height:20px;
    width:20px;
}
.app__prod-nav {
    margin: 0;
    padding: 0;
    li {
        list-style: none;
        width: 33.33333%;
        display: block;
        float: left;
        text-align: center;
        font-size: 13px;
        font-weight: 600;
        margin: 0;
        border-bottom: 1px solid darken($charcoal, 10%);
        a {
            display: block;
            padding: 5px 0;
            background: darken($charcoal, 5%);
            color: $hefe;
            transition: all 0.2s ease-in-out;
            -webkit-transition: all 0.2s ease-in-out;

            &:hover {
                background: $charcoal;
                text-decoration: none;
            }
        }
    }
}

.app h1,
.app h2,
.app h3 {
    color:#fff;
    font-size:15px;
    line-height:20px;
    margin:0 10px 5px 60px;
    overflow:hidden;
    text-overflow:ellipsis;
}
.app p {
    margin:0 0 0 60px;
}
.select-url-template.active > div + div {
    display: block;
}
.select-url-template > div + div {
    display: none;
}
.select-url-template.active > div:first-child {
    display: none;
}
.meta-prod {}
.meta-dev {}
.meta-noconfig {}
.meta-debug {
    color:#ffd11a;
}
.meta-active-users {
    display:none;
}
.app .edit-ico {
    background-color:#0f5a79;
    border:#023951 1px solid;
    border-radius:4px;
    bottom:10px;
    box-shadow:inset #2980a6 0 1px 0;
    display:none;
    position:absolute;
    right:10px;
}
.app .edit-ico:hover {
    background-color:#116b91;
    box-shadow:inset #3094bf 0 1px 0;
}
.app .edit-ico:active {
    background-color:#0b455e;
    box-shadow:none;
}
.app:hover .edit-ico,
.app:active .edit-ico,
.app.current .edit-ico {
    display:block;
}
