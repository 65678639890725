.logical {
  position: relative;
  margin: 0 2rem;
  &:after {
    border-left: $charcoal-40 1px solid;
    content: '';
    display: block;
    bottom: 16px;
    left: -20px;
    top: 16px;
    position: absolute;
  }
}
.conjuncts {
  border: $charcoal-40 1px solid;
  border-radius: 6px;
  margin: 0 0 2rem;
  &:empty {
    display: none;
  }
}
.conjunct {
  display: flex;
  align-items: center;
  position: relative;

  &__item {
    z-index: 1;
    display: flex;
    align-items: center;
    flex: 3 0 0;
    min-height: 42px;
    border-right: $charcoal-40 1px solid;
    padding: 10px;
    animation: pop 0.5s cubic-bezier(0.4, -0.45, 0.45, 1.2) forwards;
    transform-origin: left;
    a,
    > span {
      display: inline-block;
      font-size: 14px;
      padding: 10px 0;
      vertical-align: middle;
      width: 100%;
    }
    span {
      word-wrap: break-word;
    }
    .search-field input[type='search'] {
      height: 36px;
      + .icon {
        top: 12px;
      }
    }
    &:empty {
      background: #f0f0f0;
    }
    svg {
      + select,
      + input {
        width: calc(100% - 40px);
      }
    }
    &--search {
      z-index: 2;
    }
    & .select {
      width: 100%;
    }
  }
  + .conjunct {
    border-top: $charcoal-40 1px solid;
    &:after {
      background: $white;
      content: ''; // Replace with AND/OR
      color: $charcoal-40;
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 1px;
      text-align: center;
      display: block;
      padding: 2px;
      width: 30px;
      left: 20px;
      top: -8px;
      position: absolute;
      z-index: 6;
    }
  }
  &--add {
    height: 24px;
    width: 24px;
    text-align: right;
    z-index: 0;
    button {
      cursor: pointer;
    }
  }
  &--remove {
    right: -25px;
    position: absolute;
    button {
      cursor: pointer;
    }
  }
  input.-error,
  select.-error {
    border: $error 1px solid;
    width: calc(100% - 40px);
  }
}
.operator.align {
  margin-bottom: 1rem;
}
.operator,
.conjuncts--add {
  position: relative;
  flex: 1;
  justify-self: center;
  &:after {
    border-top: $charcoal-40 1px solid;
    content: '';
    display: block;
    width: 20px;
    left: -20px;
    z-index: 4;
    @include vertical-align(absolute);
  }
}
.conjuncts {
  position: relative;
  &:nth-child(n + 2) {
    &:before {
      background: $white-smoke;
      font-size: 12px;
      content: ''; // Replace with AND/OR
      color: $charcoal-40;
      font-weight: 700;
      text-align: center;
      display: block;
      padding: 2px 4px;
      width: 30px;
      position: absolute;
      left: -40px;
      bottom: -30px;
      z-index: 6;
    }
  }
}
.operand {
  &--or {
    .conjuncts:nth-child(n + 2):before {
      content: 'OR';
    }
    .conjunct + .conjunct:after {
      content: 'AND';
    }
  }
  &--and {
    .conjuncts:nth-child(n + 2):before {
      content: 'AND';
    }
    .conjunct + .conjunct:after {
      content: 'OR';
    }
  }
}
