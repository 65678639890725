input[type='checkbox'] + label,
input[type='radio'] + label {
  display: inline-block;
}
select,
textarea,
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='url'],
input[type='tel'],
input[type='color'] {
  background-color: rgb(234, 233, 233);
  border-color: rgb(99, 101, 107);
  border-style: solid;
  border-width: 1px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1em;
  padding: 5px 10px;
  vertical-align: middle;
  width: 100%;
  height: 2rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
input[type='text'].inline-editable {
  border-color: transparent;
  margin: -4px 0 0 -8px;
  &:hover {
    border-color: $charcoal;
  }
  &:focus {
    border-color: #14d0ff;
  }
}
select {
  background: #fbfbfb url(uiurl('images/select-down-caret.png')) no-repeat right
    center;
  background-size: 24px 12px;
  border: $black 1px solid;
  border-radius: 3px;
  color: $black;
  cursor: pointer;
  outline: 0;
  padding: 5px 5px 6px 10px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
// Hide select arrow in IE
select::-ms-expand {
  display: none;
}
select option {
  border: none;
  padding: 5px;
}
textarea {
  display: block;
  min-height: 80px;
  padding: 10px;
  position: relative;
  resize: vertical;
}
input[type='checkbox'],
input[type='radio'] {
  display: inline-block;
  margin: 0 5px 0 0;
}
input[type='radio'] + label {
  margin-right: 10px;
}
input[type='file'] {
  height: 2rem;
}
input[type='file']::-webkit-file-upload-button {
  height: 100%;
  vertical-align: middle;
}

/* focus styles */
:focus {
  outline: 0;
}
select:focus,
textarea:focus,
input[type='text']:focus,
input[type='checkbox']:focus,
input[type='radio']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus {
  background-color: #fff;
  border-color: $blue;
  box-shadow: none;
}
input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
button:focus {
  box-shadow: none;
}

/* fieldsets */
fieldset.submit {
}
fieldset.submit div,
fieldset .submit {
  vertical-align: middle;
  width: auto;
}
fieldset > div {
  display: block;
  margin: 0 0 15px;
  position: relative;
  vertical-align: top;
  width: 100%;
}
/* medium and small sizes */
fieldset .field-inline {
  float: left;
}
fieldset .field-inline,
fieldset .field-third,
fieldset .field-half {
  display: inline-block;
}
fieldset .field-inline + .field-inline,
fieldset .field-third + .field-third,
fieldset .field-third + .field-half,
fieldset .field-half + .field-half,
fieldset .field-half + .field-third {
  margin-left: 10px;
}
fieldset .field-third {
  width: 32%;
  width: -webkit-calc(33% - 10px);
  width: -moz-calc(33% - 10px);
  width: calc(33% - 10px);
}
fieldset .field-half {
  width: 49%;
  width: -webkit-calc(50% - 10px);
  width: -moz-calc(50% - 10px);
  width: calc(50% - 10px);
}

/* mini forms */
.mini select,
.mini input[type='text'],
.mini input[type='password'],
.mini input[type='datetime'],
.mini input[type='datetime-local'],
.mini input[type='date'],
.mini input[type='month'],
.mini input[type='time'],
.mini input[type='week'],
.mini input[type='number'],
.mini input[type='email'],
.mini input[type='url'],
.mini input[type='search'],
.mini input[type='tel'],
.mini input[type='color'],
select.mini,
input[type='text'].mini,
input[type='password'].mini,
input[type='datetime'].mini,
input[type='datetime-local'].mini,
input[type='date'].mini,
input[type='month'].mini,
input[type='time'].mini,
input[type='week'].mini,
input[type='number'].mini,
input[type='email'].mini,
input[type='url'].mini,
input[type='search'].mini,
input[type='tel'].mini,
input[type='color'].mini {
  font-size: 12px;
  height: 20px;
  line-height: 15px;
  margin: 0;
  padding: 0 5px;
  vertical-align: top;
  width: auto;
}
.mini select,
select.mini {
  background-position: 96% 55%;
  width: auto;
}
.mini fieldset div,
fieldset.mini div {
  margin-bottom: 5px;
}

select,
.mini select,
select.mini {
  padding-right: 25px;
}

/* Large Forms */
input[type='text'].large,
input[type='password'].large,
input[type='datetime'].large,
input[type='datetime-local'].large,
input[type='date'].large,
input[type='month'].large,
input[type='time'].large,
input[type='week'].large,
input[type='number'].large,
input[type='email'].large,
input[type='url'].large,
input[type='search'].large,
input[type='tel'].large,
input[type='color'].large {
  font-size: 18px;
  height: 52px;
}

/* miscellaneous form elements */
::-webkit-input-placeholder {
  color: #bfbfbf;
  white-space: normal;
}
:-moz-placeholder,
input:-moz-placeholder {
  color: #bfbfbf;
}
input[disabled='disabled'] {
  border-color: #dfdfdf;
  box-shadow: none;
}
.error input,
input.error,
input.invalid,
select.invalid,
.error textarea,
textarea.error {
  border: 1px solid #ffd11a;
  box-shadow: 0 0 2px 0 #ffd11a;
}
form.collapsed .collapsed {
  display: none;
}

/* Forms as an unordered list */
ul.form {
  margin: 0 0 15px;
  @extend %clearfix;
}
ul.form + ul.form {
  border-top: 1px dotted #ccc;
  padding-top: 14px;
}
ul.form li {
  list-style: none;
}
ul.form > li {
  margin: 0 0 15px;
}
ul.form > li:last-child,
ul.form > li:only-child {
  margin: 0;
}
ul.form > li.submit {
  background: #f7f7f7;
  margin: 0 -20px -15px;
  padding: 15px 20px;
  position: relative;
}
ul.form > li.submit > a.delete {
  color: #e92939;
  margin-top: -6px;
  right: 20px;
  position: absolute;
  top: 50%;
}
a.delete {
  color: #e92939;
}
.module ul.form > li.submit {
  background: #f7f7f7;
}
ul.form > li.section_header {
  background: #ecede8;
  margin: 0 -5px 15px;
  padding: 5px;
}

/* Deprecated */
ul.form li strong.section_header {
  display: block;
  font-size: 15px;
  line-height: 15px;
  margin: 0 0 15px;
}

/* Align form elements */
.align {
  margin: 0;
  label {
    display: inline-flex;
    font-weight: 400;
    width: auto;
    line-height: 35px;
  }
  input,
  select {
    display: inline-flex;
    font-weight: 400;
    width: auto;
    border: $black 1px solid;
    color: $black;
  }
  input[type='number'] {
    max-width: 60px;
  }
  .btn {
    display: inline-flex;
    vertical-align: middle;
  }
}

/* Joined form elements */
input[type].prefix,
select.prefix {
  border-right: none;
  margin-right: -3px;
}

select.prefix,
select.postfix {
  box-shadow: none;
  padding-left: 8px;
}

@media only screen and (max-width: 768px) {
  form,
  form fieldset,
  form fieldset > div,
  form input[type='text'],
  form textarea,
  form select {
    clear: both;
    float: none;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  fieldset div input[type='submit'] {
    display: block;
    margin-bottom: 15px;
  }
  fieldset .field-inline,
  fieldset .field-third,
  fieldset .field-half,
  fieldset .field-inline + .field-inline,
  fieldset .field-third + .field-third,
  fieldset .field-third + .field-half,
  fieldset .field-half + .field-half,
  fieldset .field-half + .field-third {
    display: block;
    margin-left: 0;
    width: 100%;
  }
}

// Project Creation Form
.project-identifiers {
  display: table;
  table-layout: fixed;
  width: 100%;
  > div {
    display: table-cell;
  }
  .project-name {
    width: 60%;
  }
  .project-icon {
    width: 40%;
    padding: 0 0 0 15px;
    img {
      height: 35px;
      width: 35px;
      margin: 0 10px 0 0;
      float: left;
    }
  }
}
.placeholder-icon {
  background-color: $teal;
  background-image: url(uiurl('images/default-app-icon.svg'));
  background-size: 32px;
  background-position: center center;
  background-repeat: no-repeat;
  height: 32px;
  overflow: hidden;
  position: relative;
  width: 32px;
  margin: 0 10px 0 0;
  float: left;
}
.platform-selection {
  display: table;
  margin: 0;
  table-layout: fixed;
  width: 100%;
  li {
    display: table-cell;
    padding-right: 15px;
    &:last-child {
      padding-right: 0;
    }
  }
  input {
    @include element-invisible;
    &:checked ~ label {
      background: $pacific-20;
      border: $pacific-80 1px solid;
    }
  }
  label {
    background: $white;
    border: $charcoal-20 1px solid;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    padding: 10px;
    text-align: center;
    width: 100%;
    transition: box-shadow 0.25s ease-in-out;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.2) 0 1px 3px;
    }
  }
  .svg-icon {
    display: block;
    margin: 0 auto 10px;
    width: 48px;
    height: 48px;
    + strong {
      color: $charcoal-60;
      font-size: 14px;
    }
  }
}

/* Custom Radio */

/*
 * 1. Overides existing container level margin
 * 2. Overides existing list item margin
 * 3. Removes border from last list item
 */
.radio_list {
  margin: -15px -10px -15px; /* 1 */

  li {
    list-style: none;
    margin: 0; /* 2 */
    padding: 10px;
    border-bottom: solid 1px $charcoal-40;

    &:hover {
      background-color: $grey-20;
    }

    &:last-of-type {
      border-bottom: none; /* 3 */
    }
  }
}

/* 1. Overides existing margin */
.radio {
  cursor: pointer;
  margin: 0; /* 1 */
  color: $charcoal-80;

  h4 {
    font-size: 16px;
  }

  p {
    margin: 0; /* 1 */
  }
}

/* Lines up radios with action menu list items
 * and targets real radio input to hide visually.
 */
.radio_input {
  float: left;
  width: 40px;
  height: 40px;
  margin: 5px;

  /* Hides real input visually */
  input {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(0 0 0 0);
  }
}

/* Styles the "fake" visible radio */
.radio_value {
  position: relative;
  display: inline-block;
  overflow: hidden;
  float: left;
  width: 1.5em;
  height: 1.9em;
  border: solid 0.2em $charcoal-60;
  border-radius: 50%;
}

/* Pseudo element to make a visible check mark */
.radio_value::before {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0,
    0 0 0 0 inset;
  margin-top: 0.15em;
  margin-left: 0.3em;
  content: '';
  transform: rotate(45deg);
}

/* Styles the checked state */
.radio_input input:checked + .radio_value {
  border-color: $pacific;
  background-color: $pacific;
  transition: all 0.5s ease;
}

/* Creates check mark */
.radio_input input:checked + .radio_value::before {
  box-shadow: 0 0 0 10px, 10px -10px 0 6px, 32px 0 0 20px, 0px 32px 0 20px,
    -5px 5px 0 8px, 20px -12px 0 0px;
  color: $white;
}

/* Gives the checked radio blue background on hover */
.radio_list li:hover .radio_input input:checked + .radio_value::before {
  color: $pacific-20;
}

.nps-form {
  background: #fff;
  border: $green 1px solid;
  position: relative;
  margin: 0 0 15px;
  iframe {
    background: #fff;
    border: none;
    height: 360px;
  }
  &--dimiss {
    position: absolute;
    right: 10px;
    top: 8px;
  }
}

.formbox {
  border-radius: 0;
  clear: both;
  margin: 0;
  padding: 10px 20px;
  padding-right: 20px;
  position: relative;
  &-cell {
    border: 1px solid rgb(199, 205, 208);
    margin-top: -1px;
    margin-left: -1px;
    background: $white;
    &:hover {
      transition: background 400ms ease;
      background: lighten($blue, 68%);
    }
    &._error {
      background: lighten($error-fill, 40%);
      border-color: $error-fill;
      z-index: 99;
    }
    .inline_value {
      display: inline-block;
      min-height: 20px;
      cursor: pointer;
    }
  }
  &-field {
    padding: 10px 20px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 600;
    color: $grey-dk;
    position: relative;
    &--input {
      color: $charcoal-60;
      text-transform: uppercase;
      display: block;
      font-size: 11px;
      font-weight: 600;
      line-height: 12px;
      input,
      textarea,
      select {
        background-color: transparent;
        border-color: transparent;
        padding: 0;
        margin: 0px 0 -7px -1px;
        color: $charcoal;
        &:focus {
          color: darken($grey, 75%);
          transition: color 200ms ease;
          background-color: transparent;
          border-color: transparent;
        }
        &::placeholder {
          color: $charcoal-60;
        }
      }
      textarea {
        margin-top: 12px;
        margin-bottom: 10px;
      }
      input[type='checkbox'] {
        vertical-align: baseline;
      }
      small {
        background: $charcoal-60;
        color: $white;
        position: absolute;
        border-radius: 35px;
        padding: 4px 6px;
        text-align: center;
        right: 20px;
        top: 20px;
        &.-error {
          background: $error-fill;
        }
      }
      .actions {
        position: absolute;
        border-radius: 35px;
        padding: 4px 6px;
        text-align: center;
        right: 20px;
        top: 20px;
        text-transform: capitalize;
      }
    }
    &--head {
      background: rgb(225, 234, 239);
      font-size: 1.2rem;
      font-weight: 500;
      svg {
        opacity: 0;
      }
    }
    &.active {
      border-color: $blue;
      background: lighten($blue, 75%);
    }
    &_dark {
      background: $grey;
    }
    &_error {
      border: none;
      background: $error;
      color: $error-fill;
    }
    &_advanced {
      min-height: 30px;
      label {
        margin-bottom: 4px;
        input[type='checkbox'] {
          vertical-align: baseline;
        }
        input[type='text'] {
          margin-top: 10px;
        }
      }
    }
    h3 {
      display: inline-block;
      float: left;
      line-height: 34px;
      margin: 0;
      padding-right: 6px;
      font-size: 12px;
      font-weight: 600;
      color: $charcoal-60;
      text-transform: uppercase;
    }
    .-warning {
      position: absolute;
      left: 4px;
      bottom: 14px;
    }
    &.-error {
      border-color: $error-fill;
    }
  }
  &--info {
    position: absolute;
    bottom: -5px;
    right: 20px;
  }
  &-actions {
    position: absolute;
    right: 0;
    opacity: 0;
    top: 0;
    line-height: 45px;
    a {
      display: inline-block;
      width: 45px;
      text-align: center;
      position: relative;
      span {
        opacity: 0;
        position: absolute;
        right: 4px;
        top: -40px;
        font-size: 0.8rem;
        overflow: hidden;
        white-space: nowrap;
      }
      &:hover {
        fill: $blue;
        span {
          opacity: 1;
        }
      }
    }
  }
  &:hover {
    .formbox-actions,
    svg {
      opacity: 1;
    }
  }
  &.active {
    background: lighten($grey, 50%);
  }
  &-head {
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 1rem;
    h2 {
      font-weight: 600;
      margin: 0;
    }
    small {
      margin: 0;
      color: $charcoal-80;
    }
  }
}

.inline-editable {
  font-size: 1.2rem;
  font-weight: 400;
  color: $charcoal-40;
  input,
  select {
    width: 75%;
    font-size: inherit;
    font-weight: inherit;
    color: $charcoal-60;
    background: transparent;
    border-color: transparent;
    margin-top: -2px;
    &:hover {
      background: inherit;
      border-color: $charcoal-20;
    }
    &:focus {
      border-color: $dawn-blue;
    }
  }
  select {
    direction: rtl;
    width: 80%;
    &:after {
      color: red;
    }
  }
}
