@import 'base/config';

.popover {
  background: $white;
  box-shadow: $shadow-md;
  display: none;
  line-height: 20px;
  position: absolute;
  white-space: normal;
  top: 50px;
  z-index: 9999;

  .popover-content {
    background: #fff;
    color: $black;
    padding: 7px;
    margin: 4px;
    position: relative;
    z-index: 100;
  }
}
.popover > ul,
.popover > ul > li,
ul.popover,
ul.popover > li,
.popover > .popover-content > ul,
.popover > .popover-content > ul > li {
  background-image: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.popover ul,
ul.popover {
  padding: 0;
}
.popover > ul,
ul.popover {
  padding: 10px;
}
.popover > ul > li > a,
ul.popover li > a,
.popover > .popover-content > ul > li > a {
  color: $black;
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin: 0 -10px;
  padding: $space-xs;
  position: relative;
  text-decoration: none;
  z-index: 100;
}
.popover > ul > li > a:hover,
ul.popover li > a:hover {
  background: $grey-40;
  color: #020202;
  cursor: pointer;
  text-decoration: none;
}
.popover ul.review-group {
  width: 350px;
  max-width: 350px;
  li {
    background: $white-smoke;
    border: $charcoal-20 1px solid;
    border-radius: 2px;
    list-style: none;
    display: inline-block;
    word-break: break-word;
    margin: 0 5px 5px 0;
    padding: 5px;
  }
}
.popover-content h4 {
  font-size: 13px;
  margin-bottom: 5px;
  span {
    text-transform: uppercase;
  }
}

/* Disabled popover drop menu item */
.popover .drop-menu > ul > li.disabled > a,
.popover .drop-menu > ul > li.disabled > a:hover {
  background-color: #e9e9e9;
  border-color: #ccc;
  box-shadow: none;
  color: #999;
  cursor: default;
  text-shadow: none;
}

.reference .arrow,
.popover .arrow {
  background: $white;
  border-color: $grey-20;
  border-style: solid;
  display: block;
  height: 10px;
  position: absolute;
  width: 10px;
  z-index: -1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.reference .arrow.up,
.popover .arrow.up {
  border-width: 1px 0 0 1px;
  left: 50%;
  margin-left: -5px;
  top: -6px;
}
.popover .arrow.right {
  border-width: 1px 1px 0 0;
  margin-top: -5px;
  right: -6px;
  top: 50%;
}
.reference .arrow.down,
.popover .arrow.down {
  border-width: 0 1px 1px 0;
  bottom: -6px;
  left: 50%;
  margin-left: -5px;
}
.popover .arrow.left {
  border-width: 0 0 1px 1px;
  left: -6px;
  margin-top: -5px;
  top: 50%;
}

/* light colored popovers */
.popover.light {
  background: $grey-20;
}
.popover.light > ul > li > a,
ul.popover.light > li > a,
.popover > .popover-content > ul > li > a {
  color: $grey;
}
.popover.light > ul > li > a:hover,
ul.popover.light > li > a:hover,
.popover > .popover-content > ul > li > a:hover {
  background: #d1e3ee;
  border-bottom-color: #b8d3e5;
  border-top-color: #dae9f2;
  box-shadow: none;
}
.popover.light .popover-content {
  background: none;
}
.popover.light .arrow {
  background: #f7f7f7;
  border-color: #ccc;
}
.popover.light .arrow.up {
  box-shadow: inset 1px 1px 0 #fff;
}

/* popover tooltips */
.popover.tooltip {
  background: transparent;
  border: none;
  font-size: 13px;
  max-width: 220px;
  pointer-events: none;
  word-break: break-word;
  text-align: center;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.popover.tooltip > div {
  background: rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
  color: #f8f5f0;
  padding: 7px 9px;
}
.popover.tooltip > div > h4 {
  color: #f8f5f0;
}
.popover.tooltip > div > h4 small {
  color: #cccac6;
}
.popover.tooltip .btn.close {
  position: absolute;
  right: -5px;
  top: -5px;
}
.popover.tooltip .arrow.up {
  background: none;
  border-bottom: rgb(0, 0, 0) 6px solid;
  border-left: rgba(0, 0, 0, 0) 6px solid;
  border-right: rgba(0, 0, 0, 0) 6px solid;
  border-top: none;
  height: 0;
  left: 50%;
  margin-left: -6px;
  width: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
}

/* Popovers with links */
.reference {
  background: $white;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: none;
  font-size: 11px;
  line-height: 20px;
  max-width: 350px;
  padding: 10px 15px;
  position: absolute;
  white-space: normal;
  z-index: 9999;

  .arrow {
    background: $white;
    border-color: #dbdbdb;
  }

  p {
    font-size: 13px;
    margin: 0 0 15px;
  }

  /* Remove bottom margin on last paragraph */
  p:last-of-type {
    margin-bottom: 0;
  }
}

/* Confirm menu */
.popover > .popover-content.confirm-menu {
  max-width: 160px;
}
.confirm-menu h5 {
  margin: 0 0 10px;
}
.confirm-menu p {
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 10px;
}
.confirm-submit {
  white-space: nowrap;
}

/* Drop menus */
.popover > .popover-content.drop-menu > ul:only-child {
  margin: -10px 0;
}

/*
  A very specific position override for the positioning
  of the help icon in the segments builder
*/
#calculating-time {
  float: left;

  a:before {
    content: 'How do we calculate time?';
    position: relative;
    top: 5px;
  }
  .inline-info-ico {
    margin: 10px 0 0 5px;
  }
}

.popover {
  &.-create {
    min-width: 128px;
    right: 3.8rem;
    top: 5.2rem;
    text-align: left;
  }
}
.-legacy {
  right: 0;
}

// Message Tables - Duplicate Popover
.duplicate-into {
  p {
    margin-bottom: 5px;
  }
  ul,
  ul li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul li {
    padding: 5px 0;
  }

  ul li a {
    font-weight: 500;
  }
}
