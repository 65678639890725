.sidebar {
    background:$pacific;
    height:100%;
    left:-240px; /* Hide by default */
    overflow-y:auto;
    position:fixed;
    top:45px;
    width:240px;
    z-index:3;
}
.sidebar .app-icon {
    float:left;
    margin:0 10px 0 0;
}
.sidebar a.app:hover {
    background:lighten($pacific, 5%);
    border-bottom-color:darken($pacific, 5%);
    border-top-color:darken($pacific, 5%);
    cursor:pointer;
}

/****** Sidebar Footer ******/
.app-footer {
    background:lighten($pacific, 5%);
    border-top:darken($pacific, 10%) 1px solid;
    bottom:0;
    height:30px;
    left:-240px; /* Hide by default */
    position:fixed;
    width:240px;
}
.app-footer .popover {
    bottom:20px;
    left:10px;
}
