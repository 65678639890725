/* Styles for Zen Desk tab for A/B test */
#zenbox_tab.ZenboxTabRight {
    position:fixed;
    bottom:26px;
    border:1px solid #bdbdbd;
    top:auto;
    cursor:pointer;
    right:20px;
    z-index:1;
    img {
        width:146px;
    }
    &:hover {
        background:#fff;
    }
    &:active {
        background:#dfdfdf;
        background:-webkit-linear-gradient(top, #dfdfdf, #fafafa);
        background:-moz-linear-gradient(top, #dfdfdf, #fafafa);
        background:linear-gradient(top, #dfdfdf, #fafafa);
        box-shadow:inset 0 1px 0 #fafafa,
            0 1px 0 rgba(255,255,255,.1);
    }
}
.ZenboxTabRight {
    border-radius:4px 4px 0 0;
    background:#fafafa;
    background:-webkit-linear-gradient(top, #fafafa, #f2f2f2);
    background:-moz-linear-gradient(top, #fafafa, #f2f2f2);
    background:linear-gradient(top, #fafafa, #f2f2f2);
    box-shadow:inset 0 1px 0 #fff,
    0 1px 1px 0 rgba(18,23,26,.1);
    display:inline-block;
    padding:2px 2px 6px 2px;
    text-decoration:none;
    white-space:normal;
    width:auto;
    /* box model */
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    /* background-color animate */
    -webkit-transition:all .2s linear;
    -moz-transition:all .2s linear;
    -ms-transition:all .2s linear;
    transition:all .2s linear;
    -webkit-font-smoothing:antialiased;
}
