/*!
  Theme: Default
  Description: Original highlight.js style
  Author: (c) Ivan Sagalaev <maniac@softwaremaniacs.org>
  Maintainer: @highlightjs/core-team
  Website: https://highlightjs.org/
  License: see project LICENSE
  Touched: 2021
*/

/*
This is left on purpose making default.css the single file that can be lifted
as-is from the repository directly without the need for a build step

Typically this "required" baseline CSS is added by `makestuff.js` during build.
*/
pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em;
}

code.hljs {
  padding: 3px 5px;
}
/* end baseline CSS */

.hljs {
  background: #f0f0f0;
  color: #444;
}

/* Base color: saturation 0; */

.hljs-subst {
  /* default */
}

/* purposely ignored */
.hljs-formula,
.hljs-attr,
.hljs-property,
.hljs-params {
}

.hljs-comment {
  color: #888888;
}
.hljs-tag,
.hljs-punctuation {
  color: #444a;
}

.hljs-tag .hljs-name,
.hljs-tag .hljs-attr {
  color: #444;
}

.hljs-name {
  color: #8f1a10;
}

/* .hljs-keyword,
.hljs-attribute,
.hljs-selector-tag,
.hljs-meta .hljs-keyword,
.hljs-doctag,
.hljs-name {
  font-weight: bold;
} */

.hljs-keyword {
  color: #800080;
}

/* User color: hue: 0 */

.hljs-type,
.hljs-string,
.hljs-number,
.hljs-selector-id,
.hljs-selector-class,
.hljs-quote,
.hljs-template-tag,
.hljs-deletion {
  color: #364aff;
}

.hljs-title,
.hljs-section {
  color: #880000;
  /* font-weight: bold; */
}

.hljs-regexp,
.hljs-symbol,
.hljs-variable,
.hljs-template-variable,
.hljs-link,
.hljs-selector-attr,
.hljs-operator,
.hljs-selector-pseudo {
  color: #364aff;
}

/* Language color: hue: 90; */

.hljs-literal {
  color: #78a960;
}

.hljs-built_in,
.hljs-bullet,
.hljs-code,
.hljs-addition,
.hljs-template-variable .hljs-name,
.hljs-template-tag .hljs-name {
  color: #48711e;
}

/* Meta color: hue: 200 */

.hljs-meta {
  color: #1f7199;
}

.hljs-meta .hljs-string {
  color: #4d99bf;
}

/* Misc effects */

.hljs-emphasis {
  font-style: italic;
}

/* .hljs-strong {
  font-weight: bold;
} */
