.v3-audience {
    font-size: 14px;
    line-height: 20px;

    ul li {
        list-style-type: none;
    }
}

.v3-audience {
    .audience-all {
        background: $white;
        border: #dbdbdb 1px solid;
        border-radius: 4px;
        padding: 20px 20px 5px 45px;
    }
}

.v3-audience ul.audience-root {

    li {
        background: $white;
        border: #dbdbdb 1px solid;
        border-radius: 4px;
        position: relative;
        margin: 0 0 50px 0;
    }

    li:last-child {
        margin: 0 0 0 0;
    }
}

.v3-audience ul.audience-root.rel-or li:after,
.v3-audience ul.audience-branch.rel-and li:after {
    background: $white;
    border-radius: 50%;
    border: 1px solid #888;
    display: block;
    height: 36px;
    font-size: 12px;
    font-weight: 700;
    left: 50%;
    line-height: 36px;
    padding: 0;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    width: 36px;
}

.v3-audience ul.audience-root.rel-or {

    li:after {
        content: "or";
        margin-left: -20px;
        margin-top: 5px;
        z-index: 2;
    }

    li:last-child:after {
        content: "";
        display: none;
    }
}

.v3-audience ul.audience-branch {
    background: $white;
    border-radius: 4px;
    padding: 5px;

    li {
        border: 0;
        margin-bottom: 0;
        @extend %clearfix;
    }

    ul.audience-branch {
        background: rgba(0,0,0,0.2);
        margin-top: 2px;

        ul.audience-branch {
            background: rgba(0,0,0,0.4);
        }
    }
}

.v3-audience li.audience-leaf {
    padding: 20px 45px;

    .leaf-label {
        display: block;
        margin-bottom: 5px;
        text-transform: capitalize;
    }
}

.v3-audience ul.audience-branch.rel-and {
    li:after {
        bottom: -20px;
        content: "and";
        margin-left: -18px;
        z-index: 1;
    }

    li:last-child:after {
        content: "";
        display: none;
    }

    li:before {
        background: #dbdbdb;
        border: 0;
        content: "";
        display: block;
        height: 1px;
        left: 0;
        margin-top: -2px;
        position: absolute;
        bottom: -1px;
        width: 100%;
        z-index: 1;
    }

    li:last-child:before {
        content: "";
        display: none;
    }
}

.v3-audience ul.audience-branch.rel-or {
    li:after {
        border: 1px dashed #dbdbdb;
        border-radius: 0;
        bottom: -2px;
        content: "";
        display: block;
        height: 1px;
        left: 16px;
        margin-top: -2px;
        position: absolute;
        width: 100%;
        z-index: 1;
    }
    li.audience-leaf:before {
        background-color: #f2f2f2;
        border: 1px solid #f2f2f2;
        border-radius: 0;
        color: #666;
        content: "or";
        display: block;
        font-size: 11px;
        font-weight: 700;
        height: 20px;
        left: 0px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        padding: 0px 0;
        position: absolute;
        top: -10px;
        width: 40px;
        z-index: 1;
    }
    li.audience-leaf:first-child:before {
        content: "";
        display: none;
    }
}

.v3-audience ul.audience-branch.rel-not {
    li.audience-leaf:before,
    li.audience-leaf:first-child:before {
        background-color: #333;
        border: 1px solid #f2f2f2;
        border-radius: 0;
        color: $white;
        content: "not";
        display: block;
        font-size: 11px;
        font-weight: 700;
        height: 20px;
        left: -6px;
        line-height: 20px;
        padding: 0px 0;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: -4px;
        width: 40px;
        z-index: 1;
    }
}

