@import 'base/config';

table {
  line-height: 20px;
  margin: 0 0 20px;
}
table.data {
  background: #fff;
  font-size: 13px;
  table-layout: fixed;
  width: 100%;
}
table.data tr th {
  padding: 5px 10px;
  text-align: left;
  white-space: normal;
  width: 100%;
  &.active {
    background-color: #e6f2fc;
  }
}
table.data tr th a {
  color: #666;
  cursor: pointer;
}
table.data tr {
  border-bottom: 1px solid #dedede;
}
table.data tr td {
  color: var(--black);
  padding: 5px 10px;
  overflow: hidden;
  word-break: break-all;
  &.disable-truncation {
    white-space: normal;
    overflow: auto;
    text-overflow: initial;
  }
}
table.data tr td a {
  font-weight: bold;
  cursor: pointer;
}
table.data tr td em {
  color: #888;
  font-style: normal;
}
table.data .summary {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.alt tbody > tr:nth-child(even) {
  background: #fbfbfb;
}
.table-actions {
  line-height: 27px;
}
.table-actions button {
  font-size: 12px;
  height: 27px;
  line-height: 20px;
  margin: 0 10px 0 0;
  padding: 3px 10px;
}

/*** Table module ***/
.table-module {
  background: #fff;
  border: #dbdbdb 1px solid;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px;
  color: #444;
  line-height: 20px;
  margin-bottom: 20px;
  position: relative;
  overflow-x: auto;
  width: 100%;
}
.table-module table {
  margin: 0;
  border-radius: 4px;
}
.table-module table tr:last-child {
  border: none;
}
.table-module table tr th:first-child {
  border-radius: 4px 0 0 0;
}
.table-module table tr th:last-child {
  border-radius: 0 4px 0 0;
}
.table-module + .table-actions,
.table-module + .pages {
  margin-top: -10px;
}

/* Grouped data table - account info */
table.data col.platform {
  width: 80px;
  border: 0 none;
}
.table-module table .numerical {
  text-align: right;
}
.table-module table tr td {
  border-top: #dbdbdb 1px solid;
}
.table-module thead + tbody {
  tr:first-child td {
    border-top: 0 none;
    word-break: break-all;
  }
}
.table-module .toggle-row {
  color: $blue;
  cursor: pointer;
  font-weight: bold;
}
.table-module .month-detail {
  display: none;
}
.table-module .month-detail.active {
  display: table-row-group;
}
.table-module .month-detail td {
  background-color: #f6f6f6;
}

/* Message table - greater than 100 */
.message-table th:first-child {
  min-width: 250px;
}
.message-table th:last-child {
  width: 100px;
}

/* Schedule table */
.schedule-table {
  th {
    &:nth-child(1) {
      width: 35%;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(5) {
      width: 15%;
    }
    &:nth-child(4) {
      width: 20%;
    }
    &:nth-child(6) {
      width: 10%;
    }
  }
}

.automation-table th {
  // Message name
  &.message-name {
    width: 20%;
  }
  // Notification
  &.messages {
    width: 20%;
  }
  // Platforms
  &.channels {
    width: 10%;
  }
  // Trigger
  &.trigger {
    width: 15%;
  }
  // Started
  &.started {
    width: 12%;
  }
  // Status
  &.status {
    width: 10%;
  }
  // Actions
  &:last-child {
    width: 13%;
  }
}

.automation-table .eight-columns th {
  // Message name
  &.message-name {
    width: 20%;
  }
  // Notification
  &.messages {
    width: 20%;
  }
  // Platforms
  &.channels {
    width: 9%;
  }
  // Trigger
  &.trigger {
    width: 10%;
  }
  // Delivery
  &.delivery {
    width: 11%;
  }
  // Started
  &.started {
    width: 10%;
  }
  // Status
  &.status {
    width: 10%;
  }
  // Actions
  &:last-child {
    width: 10%;
  }
}

.table-actions {
  font-size: 0;
  position: relative;
  text-align: right;
}
.table-actions input[type='checkbox'],
.table-actions input[type='radio'] {
  margin: 0;
  vertical-align: middle;
}
.table-actions a {
  display: inline-block;
  line-height: 1;
  text-decoration: none;
}
.table-actions a.active:after {
  content: '';
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 4px 6px 0;
  display: block;
  height: 10px;
  right: 42px;
  position: absolute;
  bottom: -5px;
  width: 10px;
  z-index: 1;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.785398163);
}

/* Temp */
input[type='submit'].delete-welcome,
.delete-welcome {
  background: none;
  border: none;
  border: 0;
  box-shadow: none;
  font-size: 12px;
  font-weight: normal;
  color: #e92939;
  padding: 0;
  right: 20px;
  bottom: 25px;
  text-shadow: none;
}

/* Old delete style */
#scheduled-table button[type='submit'],
#drafts-table button[type='submit'] {
  display: none;
}
.pages > li.delete-btn {
  float: right;
}

/* Messages tabs */
.tab-section {
  display: none;
}
.tab-section .message-limits {
  float: right;
}
.history .historical-messages,
.drafts .draft-messages,
.scheduled .scheduled-messages,
.automated .automated-messages {
  display: block;
}

.automated-messages .automation-limits,
.automated-messages .automation-frequency {
  display: inline-block;
}

.automated-messages .automation-frequency {
  border: none;
  border-right: 1px solid #333;
  margin: 0;
  margin-right: 20px;
  padding: 0;
  padding-right: 20px;
}

.automated-messages .automation-frequency .edit-config-link {
  margin: 0px 5px;
}

/* Message report */
table tr.message-report {
  background: #f5f5f5;
  overflow: hidden;
  text-align: center;
}
table tr.message-report .row {
  margin: 0;
  overflow: hidden;
}
table tr.message-report.visible .oops p {
  color: #888;
  font-size: 12px;
  padding: 10px 0 0;
  text-align: center;
}
table tr.message-report td {
  padding: 0;
}

/* Hide and show message report */
.message-report {
  display: none;
}
.message-report.visible {
  display: table-row;
}

/* Report */
.performance {
  background: #f0f0f0;
  display: table;
  table-layout: fixed;
  width: 100%;
}
.performance > div {
  display: table-cell;
  padding: 10px 0;
  text-align: center;
}
.performance .count {
  color: #666;
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  padding: 0 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.performance .count small {
  color: #888;
}
.performance h4 {
  color: #666;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
}

/* No data -- */
.no-data {
  color: #aaa;
}

.tertiary-header {
  margin: 0 0 15px;
  @extend %clearfix;
}
.tertiary-header h3 {
  float: left;
  margin: 0;
}
.tertiary-header .btn-bar {
  float: right;
}

/* New header section to replace tertiary-header */
.table-header {
  margin: 0 0 10px;
  min-height: 20px;
  position: relative;
  .table-header-actions {
    float: right;
  }
}

table.data tr td a {
  margin-right: 5px;
}

/* Disable pagination within the history table while loading new data */
.loading #history-table .pages a,
.loading div[rel='history-table'] a {
  background-color: #e9e9e9;
  background-image: none;
  border-color: #ccc;
  box-shadow: none;
  color: #999;
  cursor: not-allowed;
  pointer-events: none;
  text-shadow: none;
}

/* timezone table for push detail reports */
.timezone-table table.data {
  table-layout: auto;
}
.timezone-table col.notifications,
.timezone-table col.rich {
  width: 180px;
}
.timezone-table col.io-rate,
.timezone-table col.do-rate,
.timezone-table col.pv-rate {
  width: 120px;
}
.timezone-table .numerical {
  text-align: right;
}
.timezone-table td.io-rate,
.timezone-table td.do-rate,
.timezone-table td.pv-rate {
  background: rgba(0, 0, 0, 0.0125);
}
/* maybe delete this */
@media only screen and (max-width: 768px) {
  table tr.message-report.visible .row {
    max-height: 120px;
  }
  table tr.message-report .row > .col.three {
    clear: none;
    float: left;
    width: 50%;
  }
}

//  Events Table
div.custom-events-table,
div.events-table {
  position: relative;
  width: 100%;

  th {
    &:hover {
      cursor: pointer;
    }
    &.event,
    &.attribution,
    &.location {
      width: 200px;
    }
    &.numerical {
      width: 90px;
    }
    &.percent {
      width: 120px;
    }
  }

  td.data-not-applicable {
    background: #f5f5f5;
  }

  tfoot td {
    font-size: 16px;
    padding: 12px 10px;
    text-align: right;

    &.table-action {
      background: #f5f5f5;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5);
      text-align: center;
    }
  }

  tfoot td:first-child {
    border-right: none;
  }
}

td.attribution {
  text-transform: capitalize;
}
tfoot {
  td:first-child {
    border-right: 1px solid white;
  }
}
