// Audience
.audience-search {
  margin: 0 0 30px;
}
.audience-search_temp {
  display: -ms-flexbox;
  display: flex;
  div {
    -ms-flex-order: 1;
    order: 1;
    position: relative;
  }
  input {
    display: block;
    position: relative;
    &[type='text'] {
      border-radius: 20px;
    }
  }
  .placeholder {
    box-sizing: border-box;
    color: #9c9c9c;
    font-size: 14px;
    line-height: 20px;
    left: 14px;
    overflow: hidden;
    padding: 0 20px 0 0;
    position: absolute;
    text-overflow: ellipsis;
    top: 6px;
    white-space: nowrap;
    width: 100%;
    z-index: 1;
    pointer-events: none;
  }
}
.audience-search_label {
  color: $grey-60;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  padding: 5px 10px;
  .clear-filter {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    padding: 0;
    &:hover {
      background: none;
    }
  }
}
.audience-search_input {
  -ms-flex: 1 auto;
  flex-grow: 1;
  position: relative;
  input::-ms-clear {
    display: none;
  }
}
.audience-search_filter {
  padding: 0 0 0 10px;
  width: 26px;
}
.clear-query {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 4px;
}
.audience-meta {
  display: block;
  font-size: 12px;
}
.audience_picker {
  clear: both;
  margin: 0;
  position: relative;
}
.audience_list {
  background: $white;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.4) 0 1px 3px;
  left: 0;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  top: 0;
  transition: all 2.5s ease-in-out;
  width: 100%;
  z-index: 9;
  &.-search {
    max-height: 220px;
  }
  > li {
    border-bottom: #cfcfcf 1px solid;
    list-style: none;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    position: relative;
    a {
      display: block;
      padding: 10px;
      &:hover {
        background: #fafafa;
        .svg-sprite {
          display: block;
        }
      }
    }
    &.empty {
      padding: 10px;
    }
    .svg-sprite {
      display: none;
      right: 10px;
      @include vertical-align(absolute);
    }
  }
}
.audience-type {
  border-right: $grey-20 1px solid;
  display: inline-block;
  line-height: 1.3;
  margin: -10px 10px -10px -10px;
  padding: 13px 10px 10px;
  vertical-align: top;
  white-space: normal;
  width: 22%;
  + .audience-name {
    border-left: $grey-20 1px solid;
    margin: -10px 10px -10px -15px;
    padding: 10px;
  }
}
.audience-name {
  display: inline-block;
}
.result {
  &.-selected {
    opacity: 0.4;
  }
}
.-nav-selected {
  background: rgba(0, 142, 194, 0.1);
}
