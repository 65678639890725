

.pill {
    display: inline-block;
    min-width: 10px;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: bold;
    color: $white;
    line-height: 1;
    text-align: center;
    background-color: #777777;
    border-radius: 10px;
    word-break: break-all;
    &.--coming_soon {
        background: lighten($charcoal, 50%);
        color: $white;
    }
}
