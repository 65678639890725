
.section {
	margin: 0 0 20px;
}
.push-composer {
    .section-header {
        @extend %clearfix;
        h3 {
            float: left;
        }
    }
}
.section-header {
	h3 {
        font-size: 16px;
        font-weight: 300;
        line-height: 25px;
        margin: 0 0 10px;
    }
}
.section-header-actions {
    float: right;
    margin: -10px 0 10px;
}
