.sub-header {
  min-height: 30px;
  margin: 0 0 20px;
  position: relative;
  h2 {
    color: $charcoal;
    font-weight: 400;
    margin: 0;
    float: left;
  }
  p {
    color: var(--grey);
    margin: 0;
    max-width: 70%;
    clear: left;
  }
  &-actions {
    margin: -18px 0 0;
    position: absolute;
    right: 0;
    top: 50%;
  }
  &--bar {
    background: $charcoal-20;
    margin: -15px -20px 20px -95px;
    padding: 15px 100px 15px 95px;
    position: relative;
    .sub-header-actions {
      right: 20px;
    }
    .sub-header-title {
      margin: 0;
      height: 20px;
      line-height: 20px;
      display: inline-block;
      font-weight: 400;
    }
  }
}
.sub-header-pager {
  display: inline-block;
  margin-left: 20px;
}
.sub-header:first-child + .col {
  margin-left: 0;
}
.sub-header .view-toggle {
  float: right;
  margin-left: 20px;
  margin-right: 3px;
}
