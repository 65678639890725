.tab-nav {
  box-shadow: $charcoal-40 0 -1px 0 inset;
  display: table;
  table-layout: fixed;
  width: 100%;
  li {
    display: table-cell;
    font-weight: 500;
    position: relative;
    text-align: center;
    a {
      display: block;
      color: lighten($pacific, 10%);
      padding: 5px;
      &:hover {
        color: lighten($pacific, 20%);
        text-decoration: none;
      }
    }
    &.-active {
      font-weight: 700;
      a {
        color: $charcoal-80;
        cursor: default;
      }
      &:before {
        background: lighten($pacific, 20%);
        content: ' ';
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        bottom:0;
        left: 0;
      }
    }
  }
}

.aside-nav {
    background: $white;
    border-radius: 4px;
    box-shadow: rgba(0,0,0,.2) 0 1px 3px;
    margin: 0 20px 0 0;
    padding: 10px 0;
    > li {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
    }
    a {
        display: block;
        padding: 5px 10px;
        text-decoration: none;
        &:hover {
            background: $charcoal-20;
        }
    }
    .active a,
    .active a:hover {
        background: $link-color;
        color: $white;
    }
}
