// Colors Variables
$body: #444;
$white: #fff;
$white-smoke: #f5f5f5;
$grey-lt: #e6e6e6;
$grey: #ccc;
$grey-darkest: #444;
$grey-dk: #666;
$navy: #025172;
$blue: #1657e5;
$blue-dk: #021b26;
$blue-lt: #21a1d9;
$blue-bright: #008ec2;
$orange: #f22700;
$yellow: #ffb700;
$red: #982440;
$tan: #fafaf7;
$tan-dk: #f0f0ed;

// Brand colors
$dusk-blue: #015172;
$dawn-blue: #51c4cc;
$tuscan-sun: #97233f;
$crimson-sky: #e82839;
$misty-grey: #e0e0dc;
$pale-moon: #ece7c3;

// New brand colors
$charcoal: #020202;
$pacific: #00698f;
$hefe: #ffc727;
$honey-wheat: #eaaa00;

// Brand accents
$charcoal-20: #eceded;
$charcoal-40: #b2b7b8;
$charcoal-60: #8b9294;
$charcoal-80: #646e70;

$pacific-20: #cce1e9;
$pacific-40: #99c3d2;
$pacific-60: #66a5bc;
$pacific-80: #3387a5;

$fog: #fbfbfb;
$granite: #263438;
$slate: #778c92;

$kermit-green: #1cb864;
$link-color: lighten($pacific, 10%);

$error: #edb8b6;
$error-fill: #d14e49; // svg fill or feedback text color
$delete: $error-fill;

$success: #a1e0be;
$success-fill: #127640;

$message: #a4def4;
$message-fill: #1086b1;

$warning: #f8efd4;
$warning-fill: #cca333;

// Design system colors
$chakra-body: #1a202c; // our current black, --chakra-colors-chakra-body-text

%clearfix {
  &:after {
    clear: both;
    content: ' ';
    display: table;
  }
}
@mixin vertical-align($position: relative, $top: 50%, $offset: -50%) {
  position: $position;
  top: $top;
  -webkit-transform: translateY($offset);
  -ms-transform: translateY($offset);
  transform: translateY($offset);
}

@mixin element-invisible {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}
