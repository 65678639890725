// Generic grid styles.
@import 'settings';

$gutter-width: 1em;

.wrap {
    display: block;
    max-width: 1230px;
    height: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 0 auto;
    position: relative;
    @extend %clearfix;
}

.layout-grid,
.block-grid {
    overflow: hidden;
}

.layout-grid__row,
.block-grid__row {
    @extend %clearfix;
    margin-left: -$gutter-width;
    margin-right: -$gutter-width;
}

.layout-grid__column {
    float: left;
    padding: 0 $gutter-width;
    width: 100%;
}

.block-grid__block {
    float: left;
    padding: 0;
    width: 100%;
}

.layout-grid--cols-2 .layout-grid__column,
.block-grid--cols-2 .block-grid__block {
    width: (1/2) * 100%;
}

.layout-grid--cols-3 .layout-grid__column,
.block-grid--cols-3 .block-grid__block {
    width: (1/3) * 100%;
}

.layout-grid--cols-4 .layout-grid__column,
.block-grid--cols-4 .block-grid__block {
    width: (1/4) * 100%;
}

.layout-grid--cols-5 .layout-grid__column {
    width: (1/5) * 100%;
}

.layout-grid--cols-6 .layout-grid__column {
    width: (1/6) * 100%;
}
