.selection-list {
    border:adjust-color($pacific, $lightness: 35%, $saturation: -40%) 1px solid;
    box-shadow:0 1px 1px 0 rgba(18,22,26,.1);
    border-radius:4px;
    margin:0 0 10px;
    padding:0;
}
.selection-list:empty {
    display:none;
}
.selection-list > li {
    background:adjust-color($pacific, $lightness: 70%, $saturation: -40%);
    border-bottom:adjust-color($pacific, $lightness: 35%, $saturation: -40%) 1px solid;
    color:$charcoal;
    font-size:14px;
    font-weight:400;
    line-height:20px;
    list-style:none;
    margin:0;
    padding:5px 10px;
    position:relative;
    word-break:break-word;

    .meta {
        color:lighten($charcoal, 20%);
        margin: 0 5px 0 0;
    }
}
.selection-list > li:first-child {
    border-radius:4px 4px 0 0;
}
.selection-list > li:last-child {
    border:none;
    border-radius:0 0 4px 4px;
}
.selection-list > li:only-child {
    border-radius:4px;
}
.selection-list > li .close-ico {
    margin:-10px 0 0;
    position:absolute;
    right:10px;
    top:50%;
}

// Messages inside composer options
.selection {
    .static.message {
        h4 {
            padding: 10px 10px 0;
        }
        p {
            padding: 0 10px 10px;
        }
    }
}


// List selections 
.selected-lists-list {
    margin-right: 40px;

    > li {
        position: relative;
    }
}
.selected-lists-error {
    margin: -10px 0 0;
    position: absolute;
    right: -30px;
    top: 50%;
}


/* Selection */
.select-group {
    margin:0 -10px 20px;
    @extend %clearfix;
}
.select-thumb {
    float:left;
    text-align:center;
    width:25%;
}
.select-thumb > h5,
.select-thumb > a {
    font-size:14px;
    margin-bottom:5px;
    text-align:center;
}
.select-thumb > a:hover span {
    -webkit-transition:all .25s ease-in-out;
    transition:all .25s ease-in-out;
    -webkit-transform:scale(1.2);
    -ms-transform:scale(1.2);
    transform:scale(1.2);
}
.selection {
    background:#fff;
    border-radius:4px;
    box-shadow:rgba(0,0,0,.1) 0 1px 2px;
    clear:both;
    margin:0 0 20px;
    padding:20px;
    position:relative;
    @extend %clearfix;
}
.selection-header {
    background:#f0f0f0;
    border-bottom:#dbdbdb 1px solid;
    border-radius:4px 4px 0 0;
    margin:-20px -20px 20px;
    padding:6px 10px;

    &.error {
        background:#fff9e0;
        border-color:#ffd11a;
    }
    &.message{
        background:#f2feff;
        border-color:#51c4cc;
    }
}
.selection-indent {
    padding-left:95px;
    @extend %clearfix;
}
.selection-thumb {
    float:left;
    margin:0 0 0 -95px;
}
.selection-thumb span {
    display:block;
    margin:0 auto;
}
.selection-info {
    float:left;
    padding:10px 0 0;
    width:100%;
}
.selection-section {
    border-top:#dedede 1px solid;
    margin:20px -20px 0;
    padding:20px 20px 0;
}
.selection-clear {
    position:absolute;
    right:5px;
    top:5px;
}
.sub-option {
    clear:both;
    margin:0 0 20px;

    .selection {
        display:none;
        margin:5px 0 20px;
    }
    input[type="checkbox"]:checked ~ .selection {
        display:block;
    }
}
