.email-preview {
  .mobile {
    background-image: url(uiurl('images/device-preview_iOS_email.png'));
    background-repeat: no-repeat;
    background-position: center top;
    width: 24em;
    height: 629px;
    margin: auto;
    background-size: 350px;

    .mobile-message {
      position: relative;
      top: 60px;
      left: 37px;
      width: 269px;
      max-height: 546px;
      border-bottom-left-radius: 28px;
      border-bottom-right-radius: 28px;
      overflow: scroll;

      textarea {
        width: 270px;
        height: 445px;
        resize: none;
      }

      iframe {
        background: $white;
        border: none;
        width: 345px;
        height: 688px;
        margin-left: -38px;
        margin-top: -66px;
        transform: scale(0.78);
      }

      iframe.plaintext {
        overflow-wrap: break-word;
      }
      .email-meta {
        font-size: 12px;
        height: 70px;
        & + iframe {
          height: 610px;
          width: 345px;
        }
      }
    }
  }

  .mobile-message--plain-text {
    height: 30em;
    overflow: scroll;
    white-space: pre-wrap;
    font-size: 1.2em;
  }

  // In order to have breaks we added pre-wrap, this moves the content
  // down and is unsightly. I am jankily adding a negative margin to offset it.
  // .plain-text--content {
  //   margin-top: -3rem;
  // }

  .mobile-message--browser {
    border: solid 1px $grey-40;
    max-width: 400px;
    iframe {
      border: none;
      width: 320px;
      height: 37rem;
    }
  }

  .html {
    border: solid 1px $grey-40;
    max-width: 24rem;
    height: 37rem;
    overflow: auto;
    padding: $space-sm;
    background: $grey-20;
  }
}
