.protip {
    border-radius:4px;
    font-size:12px;
    line-height:15px;
    overflow:hidden;
    margin:0 0 20px;
    padding:0 15px;

    ol {
        font-size: 14px;
        line-height: 20px;
    }
}
.protip > :last-child {
    margin-bottom:0;
}
.protip strong {
    color:#333;
}
.protip .bullets > li > a {
    font-weight:bold;
}
.protip blockquote {
    background:#fcfcf9;
    background:rgba(255,255,255,.8);
    font-size:14px;
    line-height:20px;
    margin:0 0 10px;
}
.protip.attribution p {
    font-size: 12px;
    margin-bottom: 10px;
}
