.toolbar-item {
    display:inline-block;
    margin-right:10px;
}
.toolbar-item:last-child {
    margin-right:0;
}
.toolbar-item > * {
    display:inline-block;
    vertical-align:middle;
}

.toolbar-item select {
    background-color: $white-smoke;
    border-radius: 4px;
    border-width: 2px;
    font-weight: 600;
}
.toolbar-item select:first-child {
    margin-left: 0.5em;
}
