/****** Icons ******/
.icon {
    background:transparent url(uiurl('images/icons.png')) no-repeat 0 0;
    display:inline-block;
    height:16px;
    overflow:hidden;
    pointer-events:none;
    width:16px;
    vertical-align:middle;

    &.remove {
        background-position:0 -75px;
    }
    &.segment-edit {
        background-position:-75px -75px;
    }
    &.nested {
        background-position:-25px -75px;
    }
    &.calendar {
       background-position:-50px -75px;
    }
    &.search {
        background-position:-125px -100px;
    }
    &.web {
        width: 18px;
        background-position:0px -150px;
    }
    &.sms {
        width: 18px;
        background-position:-25px -150px;
    }
    &.email {
        width: 18px;
        background-position:-50px -150px;
    }
    &.apple,
    &.aps,
    &.ios {
        background-position:0px -175px;
    }
    &.android,
    &.google {
        background-position:-25px -175px;
    }
    &.windows,
    &.wns {
        background-position:-50px -175px;
    }
    &.blackberry {
        background-position:-75px -175px;
    }
    &.new-windows,
    &.mpns {
        background-position:-100px -175px;
    }
    &.amazon {
        background-position:-125px -175px;
    }
    &.explore {
        background-position:-100px -100px;
    }
    &.options {
        background-position:-50px -100px;
        width:10px;
    }
    &.share {
        background-position:-100px 0;
        width:18px;
    }
    &.download {
        background-position:-125px 0;
    }
    &.select {
        background-position:-50px 0;
        width:10px;
    }
    &.segments {
        background-position:-75px -50px;
    }
    &.inapp-ico {
        background-position:0 -125px;
    }
    &.sub-ico {
        background-position:0 -150px;
    }
    &.print {
        background-position:-75px -25px;
    }
    &.image {
        background-position:-100px -25px;
    }
    &.csv {
        background-position:-125px -25px;
    }
    &.aud-one,
    &.aud-device_token {
        background-position:-100px -50px;
        width:20px;
    }
    &.aud-all {
        background-position:-125px -50px;
        width:20px;
    }
    &.aud-tag {
        background-position:-50px -50px;
        width:20px;
    }
    &.aud-location {
        background-position:-125px -75px;
        width:20px;
    }
    &.aud-segment {
        background-position:-100px -75px;
        width:20px;
    }
    &.aud-test {
        background-position:-25px -50px;
        height:13px;
        width:10px;
    }
}

/****** Sprites ******/
.sprite {
    background-image:url(uiurl('images/sprites.png'));
    background-repeat:no-repeat;
    display:inline-block;
    font-size:0;
    padding:0;
    vertical-align:middle;
}
.overview-ico {
    background-position:0 0;
}
.messages-ico {
    background-position:0 -25px;
}
.audience-ico {
    background-position:0 -50px;
}
.reports-ico {
    background-position:0 -75px;
}
.settings-ico {
    background-position:0 -100px;
}
.dashboards-ico {
    background-position:0 -200px;
    height:22px;
    width:23px;
}
.connect-ico {
    background-position:0 -224px;
}
.nav-ico {
    background-position:-50px 0;
    height:20px;
    width:20px;
}
.down-arrow-ico {
    background-position:-50px -25px;
    height:10px;
    width:13px;
}
.back-out-ico {
    background-position: -25px -25px;
    height:20px;
    width:20px;
}
.right-arrow-ico {
    background-position:-50px -150px;
    height:20px;
    width:20px;
}
.plus-ico {
    background-position:-50px -75px;
    height:16px;
    width:16px;
}
.remove-ico {
    background-position:-50px -200px;
    height:16px;
    width:16px;
}
.create-ico {
    background-position:-50px -50px;
    height:20px;
    width:20px;
}
.error-ico {
    background-position:-125px -75px;
    height:20px;
    width:21px;
}
.warning-ico {
    background-position:-125px -100px;
    height:20px;
    width:21px;
}
.view-ico {
    background-position:-50px -100px;
    height:21px;
    width:20px;
}
.gear-ico {
    background-position:-75px -100px;
    height:21px;
    width:20px;
}
.prompt-ico {
    background-position:-100px -25px;
    height:20px;
    width:20px;
}
.inline-error-ico {
    background-position:-100px -25px;
    height:20px;
    width:20px;
}
.console-ico {
    background-position:-100px -50px;
    height:20px;
    width:20px;
}
.info-ico {
    background-position:-75px -50px;
    height:20px;
    width:20px;
}
.sort-ico {
    background-position:-175px -150px;
    height:11px;
    width:10px;
}
.descending .sort-ico {
    background-position:-225px -150px;
}
.ascending .sort-ico {
    background-position:-200px -150px;
}
.close-ico {
    background-position:-75px -25px;
    height:20px;
    width:20px;
}
.edit-ico {
    background-position:-100px -100px;
    height:20px;
    width:20px;
}
.account-ico {
    background-position:-25px -175px;
    height:20px;
    width:25px;
}
.team-ico {
    background-position:-50px -175px;
    height:20px;
    width:25px;
}
.security-ico {
    background-position:0 -175px;
    height:20px;
    width:25px;
}
.billing-ico {
    background-position:-75px -175px;
    height:20px;
    width:25px;
}
.apps-sml-ico {
    background-position:-75px 0;
    height:20px;
    width:20px;
}
.account-sml-ico {
    background-position:-100px 0;
    height:20px;
    width:20px;
}
.help-sml-ico {
    background-position:-125px 0;
    height:20px;
    width:20px;
}
.new-ico {
    background-position:-50px -125px;
    height:16px;
    width:16px;
}
.plus-sml-ico {
    background-position:-175px 0;
    height:18px;
    width:15px;
}
.back-ico {
    background-position:-150px -150px;
    height:12px;
    width:14px;
}
a:hover .plus-sml-ico,
a:active .plus-sml-ico,
a:focus .plus-sml-ico {
    background-position:-175px -25px;
}
.plus-lrg-ico {
    background-position:-50px -100px;
    height:24px;
    width:24px;
}
.inline-help-ico {
    background-position:-125px -25px;
    height:15px;
    width:15px;
}
.inline-help:hover .inline-help-ico {
    background-position:-125px -50px;
    cursor:pointer;
}
.inline-info-ico {
    background-position:-150px -50px;
    height:15px;
    width:15px;
}
.inline-info-ico:hover {
    background-position:-150px -75px;
    cursor:pointer;
}
.prev-arrow {
    background-position:-175px -100px;
    height:9px;
    width:8px;
}
.next-arrow {
    background-position:-200px -100px;
    height:9px;
    width:8px;
}
.rounded-arrow-right {
    background-position: -50px -150px;
    height:20px;
    width:20px;
}
.rounded-arrow-left {
    background-position: -25px -150px;
    height:20px;
    width:20px;
}
.notification-ico {
    background-position:-175px -75px;
    height:20px;
    width:20px;
}
.actions-ico,
.rich-media-ico {
    background-position:-200px -75px;
    height:20px;
    width:20px;
}
.engage-ico {
    background-position:-250px -75px;
    height:20px;
    width:20px;
}
.target-audience-ico {
    background-position:-225px -75px;
    height:18px;
    width:20px;
}
.delivery-ico {
    background-position:-225px -50px;
    height:20px;
    width:20px;
}
.preview-ico {
    background-position:-100px -150px;
    height:13px;
    width:18px;
}
.editable-ico {
    background-position:-100px -175px;
    height:16px;
    width:14px;
}
.duplicatable-ico {
    background-position:-100px -225px;
    height:18px;
    width:18px;
}
.add-ico {
    background-position:-75px -100px;
    height:20px;
    width:20px;
}
.pause-ico {
    background-position:-225px -225px;
    height:18px;
    width:16px;
}
.play-ico {
    background-position:-250px -225px;
    height:18px;
    width:16px;
}
.stop-ico {
    background-position:-275px -225px;
    height:18px;
    width:16px;
}
.automation-ico {
    background-position:-275px -75px;
    height:24px;
    width:19px;
}
.options-ico {
    background-position:-225px -100px;
    height:20px;
    width:18px;
}
a:hover .options-ico,
a.active .options-ico,
a:focus .options-ico {
    background-position:-225px -125px;
    height:20px;
    width:18px;
}
.toggle-ico {
    background-position:-250px -150px;
    height:21px;
    width:46px;
}
.toggle-ico.off {
    background-position:-250px -175px;
}
.preview-action-ico {
    background-position:-250px 0;
    height:13px;
    width:18px;
}
a:hover .preview-action-ico,
a.active .preview-action-ico {
    background-position:-250px -25px;
}
.duplicate-action-ico {
    background-position:-150px 0;
    height:20px;
    width:18px;
}
a:hover .duplicate-action-ico,
a.active .duplicate-action-ico {
    background-position:-150px -25px;
}
.delete-action-ico {
    background-position:-200px 0;
    height:21px;
    width:15px;
}
a:hover .delete-action-ico,
a.active .delete-action-ico {
    background-position:-200px -25px;
}
.details-action-ico {
    background-position:-250px 0;
    height:22px;
    width:24px;
}
a:hover .details-action-ico,
a.active .details-action-ico {
    background-position:-250px -25px;
}
.reports-action-ico {
    background-position:-225px 0;
    height:18px;
    width:19px;
}
a:hover .reports-action-ico,
a.active .reports-action-ico {
    background-position:-225px -25px;
}
.edit-action-ico {
    background-position:-275px 0;
    height:16px;
    width:14px;
}
a:hover .edit-action-ico,
a.active .edit-action-ico {
    background-position:-275px -25px;
}
a.non-editable:hover .edit-action-ico {
    background-position:-275px 0;
}
a.non-editable:hover .duplicate-action-ico {
    background-position:-150px 0;
}
.move-arrow-ico {
    background-position:-170px -50px;
    height:25px;
    width:25px;
}
.rate {
    background-position:-175px -150px;
    height:34px;
    width:68px;
}
.rotate-ico {
    background-position:-175px -125px;
    height:17px;
    width:16px;
}
.uncheck-ico {
    background-position:-150px -200px;
    height:20px;
    width:19px;
}
.check-ico {
    background-position:-175px -200px;
    height:20px;
    width:19px;
}
.upload-ico {
    background-position:-100px -200px;
    height:14px;
    width:11px;
}
.upload-action-ico {
    background-position:-75px -225px;
    height:14px;
    width:11px;
}
a:hover .upload-action-ico,
a:focus .upload-action-ico,
a:active .upload-action-ico {
    background-position:-125px -225px;
}
.download-ico {
    background-position:-75px -200px;
    height:14px;
    width:11px;
}
.locked {
    background-position: -23px 7px;
    height:35px;
    width:20px;
}
.expand-ico {
    background-position:-250px -125px;
    height:18px;
    width:16px;
}
.contract-ico {
    background-position:-250px  -100px;
    height:18px;
    width:16px
}
.sync-ico {
    background-position:-175px  -175px;
    height:19px;
    width:21px
}
.gaffe-ico {
    background-position:-100px  -250px;
    height:16px;
    width:16px
}

/* Checkbox replacement */
input[type="checkbox"].check-replace {
    height:auto;
    margin:1px 4px 0 0;
    position:relative;
    width:auto;
    visibility:hidden;
}
input[type="checkbox"].check-replace:before {
    background-image:url(uiurl('images/sprites.png'));
    background-position:-150px -225px;
    bottom:0;
    content:' ';
    display:inline-block;
    height:16px;
    left:0;
    overflow:hidden;
    position:absolute;
    right:0;
    top:0;
    width:19px;
    visibility:visible;
}
input[type="checkbox"].check-replace:hover {
    cursor:pointer;
}
input[type="checkbox"]:checked.check-replace:before {
    background-position:-175px -225px;
}

/****** App Services ******/
.service-icon {
    background:url(uiurl('images/services-icons.png')) no-repeat 0 0;
    float:left;
    height:60px;
    margin:0 10px 0 0;
    width:60px;
}
.ios_push .service-icon {
    background-position:0 0;
}
.ios_iap .service-icon {
    background-position:-60px 0;
}
.blackberry .service-icon {
    background-position:-120px 0;
}
.amazon .service-icon {
    background-position:-180px 0;
}
.android_gcm .service-icon {
    background-position:0 -60px;
}
.android_iap .service-icon {
    background-position:-60px -60px;
}
.android_he .service-icon {
    background-position:-120px -60px;
}
.android_c2dm .service-icon {
    background-position:-180px -60px;
}
.windows_wns .service-icon {
    background-position:0 -120px;
}
.windows_mpns .service-icon {
    background-position:-60px -120px;
}
.windows_he .service-icon {
    background-position:-120px -120px;
}

/****** Platform logos ******/
.platform-logo {
    background:url(uiurl('images/platform-logos.png')) no-repeat 0 0;
    display:inline-block;
    height:20px;
    margin:0 5px 0 0;
    vertical-align:middle;
    width:20px;
}
.apple-logo {
    background-position:0 0;
}
.android-logo {
    background-position:-20px 0;
}
.windows-wns-logo, .wns-logo {
    background-position:-40px 0;
}
.windows-mpns-logo, .mpns-logo {
    background-position:-60px 0;
}
.windows-he-logo {
    background-position:-80px 0;
}
.blackberry-logo {
    background-position:-100px 0;
}
.amazon-logo {
    background-position:-120px 0;
}
.web-logo {
    background-position:-140px 0;
    width: 22px;
}
/* Select icons and colors */
.select-icon {
    background-image:url(uiurl('images/select-icons.png'));
    background-repeat:no-repeat;
    border-radius:50px;
    display:block;
    height:75px;
    margin:0 auto 10px;
    padding:0;
    width:75px;

    &.message-center-ico {
        background-position:0 0;
    }
    &.landing-ico {
        background-position:-75px 0;
    }
    &.deep-link-ico {
        background-position:0 -75px;
    }
    &.url-ico {
        background-position:-75px -75px;
    }
    &.share-ico {
        background-position:-225px -75px;
    }
     &.list-ico {
        background-position:-75px -225px;
    }
    &.segments-ico {
        background-position:-150px 0;
    }
    &.tags-ico {
        background-position:-150px -75px;
    }
    &.add-tags-ico {
        background-position:0 -150px;
    }
    &.remove-tags-ico {
        background-position:-75px -150px;
    }
    &.activity-ico {
        background-position:-225px 0;
    }
    &.app-open-ico {
        background-position:-150px -150px;
    }
    &.first-app-open-ico {
        background-position:-225px -150px;
    }
    &.location-ico {
        background-position:0 -225px;
    }
    &.action {
        background-color:#21a1d9;
    }
    &.automation {
        background-color:#e82839;
    }
    &.audience {
       background-color:#a8d64b;
    }
}

/* Retina */
@media
only screen and (-webkit-min-device-pixel-ratio:2),
only screen and (min--moz-device-pixel-ratio:2),
only screen and (-o-min-device-pixel-ratio:2/1),
only screen and (min-device-pixel-ratio:2),
only screen and (min-resolution:192dpi),
only screen and (min-resolution:2dppx) {
    .icon {
        background-image:url(uiurl('images/icons@2x.png'));
        background-size:150px 200px;
    }
    .sprite {
        background-image:url(uiurl('images/sprites@2x.png'));
        background-size:300px 300px;
    }
    .service-icon {
        background-image:url(uiurl('images/services-icons@2x.png'));
        background-size:240px 180px;
    }
    .service-badge {
        background-image:url(uiurl('images/services-badges@2x.png'));
        background-size:110px 110px;
    }
    .platform-logo {
        background-image:url(uiurl('images/platform-logos@2x.png'));
        background-size:159px 20px;
    }
    .select-icon {
        background-image:url(uiurl('images/select-icons@2x.png'));
        background-size:300px 300px;
    }
}
