.sub-nav {
    margin:0 0 90px;
    position:relative;
}
.sub-nav .sprite  {
    height:22px;
    margin:0 5px 0 0;
    width:25px;
}

/* default state */
.sub-nav > li {
    border-bottom:darken($pacific, 5%) 1px solid;
    font-size:14px;
    font-weight:700;
    line-height:20px;
    list-style:none;
    margin:0;
}
.sub-nav > li > a {
    color:#fff;
    display:block;
    padding:10px 20px;
}
.sub-nav > li > a:hover {
    background:lighten($pacific, 5%);
    text-decoration:none;
}
.sub-nav > li > a:active {}

/* .current state */
.sub-nav > li.current {
    background:darken($pacific, 5%);
}
.sub-nav > li.current > a {
    color:#fff;
}
.sub-nav > li.current > a:hover {
    background:none;
}
.sub-nav > li.current > a:only-child {
    background:none;
}
.sub-nav > li.current > ul {
    display:block;
}

/* sub-nav sub-nav */
.sub-nav > li > ul {
    display:none;
    list-style:none;
    margin:0 0 15px 40px;
}
.sub-nav > li > ul > li {
    font-size:12px;
    line-height:15px;
    list-style:none;
    margin:0 20px 10px 0;
}
.sub-nav > li > ul > li:last-child {
    margin-bottom:0;
}
.sub-nav > li > ul > li > a {
    border-radius:20px;
    color:#fff;
    display:inline-block;
    padding:5px 15px;
    text-shadow:none;
}
.sub-nav ul > li > a:hover,
.sub-nav ul > li.current > a {
    background:darken($pacific, 10%);
    text-decoration:none;
}
.sub-nav > li > ul > li a:active {
     background:darken($pacific, 10%);
}
.sub-nav > li > ul > li.current > a {
    color:#fff;
}
