@import 'settings';

ul.errorlist {
  margin: 0;
  padding: 0;
}

ul.errorlist li {
  list-style: none;
  margin: 0 0 15px;
  background: #fff;
  border: 1px solid #ffb700;
  border-left-width: 11.237px;
  border-left-width: 0.70233rem;
  color: #020202;
  font-size: 12.641px;
  font-size: 0.79012rem;
  font-weight: 500;
  line-height: normal;
  width: auto;
  padding: 11.237px;
  padding: 0.70233rem;
  white-space: normal;
  box-sizing: border-box;
}

// ul.errorlist {
//   margin: 0;
//   padding: 0;
//   color: #ff2828;
//   font-size: $font-size-sm;
// }
// ul.errorlist li {
//   background: none;
//   display: block;
//   font-weight: bold;
//   list-style: none;
//   margin: 0;
//   padding: 0 10px;
//   position: relative;
// }
// ul.errorlist li.error {
//   border: none;
//   box-shadow: none;
// }

// ul.errorlist,
// #flash_error {
//   background: #fff9e0;
//   border: 2px solid #ffd11a;
//   border-radius: 0 0 4px 4px;
//   color: #332900;
//   margin: -1px 10px 0;
//   padding: 5px 0;
// }
// #flash_error {
//   border-radius: 4px;
//   margin: 0 0 20px;
//   padding: 5px 10px;
// }

// ul.errorlist ~ input,
// ul.errorlist ~ input[type='text'],
// ul.errorlist ~ input[type='password'] {
//   background: #fff;
//   border: 1px solid #ffd11a;
// }
// ul + .errorlist,
// label + .errorlist,
// .errorlist:only-child {
//   border-radius: 4px;
// }
// .errorlist:first-child {
//   border-radius: 4px;
//   margin: 0;
// }

.flash {
  background: #f5f5f5;
  color: #333;
  margin: 0 auto 20px;
  p {
    line-height: 18px;
    margin: 0;
    padding: 10px;
  }
  .product-name {
    text-transform: capitalize;
  }
}
.static {
  background: #f5f5f5;
  color: #333;
  margin-bottom: 15px;
  padding: 10px;
}
// Validation
.feedback {
  display: block;
  margin: 1rem;
  padding: $space-sm;
  box-sizing: border-box;
  &.hidden {
    display: none;
  }
  h3 {
    margin: 0 0 10px;
  }
  p {
    margin: 0;
  }
  a {
    text-decoration: underline;
    color: darken(rgba(35, 181, 235, 1), 25%);
  }
}

.feedback.-success,
.flash.success,
.static.success,
.module-header.success,
.module-footer.success {
  background: $success;
  color: $success-fill;
}

.feedback.-warning,
.flash.warning,
.static.warning,
.module-header.warning,
.module-footer.warning {
  background: $warning;
  color: $warning-fill;
}

.feedback.-error,
.flash.error,
.static.error,
.module-header.error,
.module-footer.error {
  background: $error;
  color: $error-fill;
}
.feedback.-message,
.flash.info,
.flash.message,
.static.message,
.module-header.message,
.module-footer.message {
  background: $message;
  color: $message-fill;
}
.content > .feedback {
  margin: 0;
}

.static.attention {
  background: #23b5eb;
  color: $white;
  border: 0;
  position: relative;

  .arrow-up {
    position: absolute;
    left: 25px;
    top: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #23b5eb;
  }

  a {
    color: $white;
    text-decoration: none;
    border-bottom: 1px solid $white;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;

    &:hover {
    }
  }
}
.notification p,
.flash p,
.static p {
  line-height: 18px;
  margin: 0;
  padding: 10px;
}
.header + .flash {
  margin: 0;
}

/* Display error when editng a scheduled message  */
.edit-scheduled,
.delete-scheduled,
.cancel-scheduled {
  display: none;
}
.invalid-edit-scheduled .edit-scheduled,
.invalid-delete-scheduled .delete-scheduled,
.invalid-cancel-scheduled .cancel-scheduled {
  display: block;
}

.notification {
  background: rgba(255, 255, 255, 0.6);
  display: none;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  -webkit-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}
.notification > div {
  background: #ccc;
  border: 5px solid #999;
  border-radius: 10px;
  box-shadow:
    inset 0 1px 0 0 rgba(255, 255, 255, 0.6),
    0 0 10px 0 rgba(0, 0, 0, 0.3);
  color: #333;
  font-weight: bold;
  right: 20px;
  line-height: 15px;
  margin: 0;
  opacity: 0;
  position: fixed;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
  top: 55px;
  -webkit-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}
.notification > div h3,
.notification > div ul {
  margin: 0;
}
.notification > div ul li {
  margin: 0 0 5px;
}
.notification > div ul li:last-child {
  margin: 0;
}
.notification > div ul li p {
  font-weight: normal;
  margin: 0;
}

.updating .notification,
.deleting .notification,
.reading .notification,
.saving .notification,
.invalid .notification {
  display: block;
  opacity: 1;
}

.reading.success .notification .reading.success,
.reading.failure .notification .reading.failure,
.saving.success .notification .saving.success,
.saving.failure .notification .saving.failure,
.invalid .notification .invalid {
  opacity: 1;
}

.notification .success {
  background: #e7f2da;
  border-color: #85e517;
}
.notification .failure,
.notification .invalid {
  background: #fff9e0;
  border-color: #ffd11a;
}

/****** Announcements ******/
.announcement {
  background: $message;
  color: $message-fill;
  margin: 0;
  padding: 10px 40px 10px 10px;
  position: relative;
  p {
    margin: 0;
  }
}

.header ~ .announcement .close-ico {
  position: absolute;
  right: 10px;
  top: 8px;
}

.header + .announcement ~ .announcement {
  border-top: 1px solid $fog;
}

/****** Banner ******/
.banner {
  background: #fffef2;
  border-radius: 8px;
  border: #dfdfdf 1px solid;
  -webkit-animation: fadeInUp 0.5s ease-in-out both;
  animation: fadeInUp 0.5s ease-in-out both;
}
.banner .arrow.down {
  background: #fffef2;
  border-color: #fffef2;
  border-bottom: #dfdfdf 1px solid;
  border-right: #dfdfdf 1px solid;

  border-style: solid;
  bottom: -7px;
  display: block;
  height: 10px;
  position: absolute;
  right: 35px;
  width: 10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.banner > p {
  margin: 0;
  padding: 15px;
  text-align: center;
}
.introduction {
  -webkit-animation: introduction 1s ease-in-out;
  animation: introduction 1 ease-in-out;
}

/* Image upload */
.img-preview {
  background: #f0f0f0;
  border-radius: 4px;
  display: inline-block;
  line-height: 50px;
  overflow: hidden;
  padding: 5px;
  position: relative;
}
.img-preview img,
.img-preview .img {
  background: #ccc;
  float: left;
  height: 50px;
  min-width: 50px;
  max-width: 100px;
}
.img-preview [rel='remove'] {
  float: left;
  padding: 0 10px;
  vertical-align: middle;
}
.label-error {
  background: #fff9e0;
  border: 2px solid #ffd11a;
  border-radius: 4px;
  color: #332900;
  display: none;
  font-weight: bold;
  margin: 10px 0;
  padding: 5px;
}
.file-too-big .label-error.file-too-big,
.wrong-file-type .label-error.wrong-file-type,
.error-while-uploading .label-error.error-while-uploading,
.in-app-display-form div.label-error {
  display: block;
}

/* Image loading managment */
.loading .img-preview.placeholder {
  display: inline-block;
}
.loading [rel='select'],
.img-preview.placeholder {
  display: none;
}
.img-preview .loading-mask .spinner {
  left: 30px;
}

.main > .static.message {
  p {
    font-size: 12px;
    padding: 0;
  }
}
.inline-error {
  display: inline-block;
  padding: 5px 10px;
  color: #ff2828;
}
.non-editable {
  cursor: default;
  opacity: 0.6;
}

.toast-messages {
  bottom: 60px;
  left: 10px;
  margin: 0;
  position: fixed;
  > li {
    background: rgba(35, 181, 235, 0.4);
    border-radius: 3px;
    color: darken(rgba(35, 181, 235, 1), 15%);
    display: block;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    padding: 10px;
    white-space: normal;
    box-sizing: border-box;
    -webkit-animation: toasty 5s ease-in-out forwards;
    animation: toasty 5s ease-in-out forwards;
    z-index: 9999;
    &.-error {
      background: $error;
      color: $error-fill;
    }
    &.-success {
      background: $success;
      color: $success-fill;
    }
  }
}
.inline-toast {
  position: absolute;
  right: -1px;
  top: -1px;
  padding: 6px 10px;
  -webkit-animation: toasty 5s ease-in-out forwards;
  animation: toasty 5s ease-in-out forwards;
  &.-success {
    background: $success;
    color: $success-fill;
  }
}
