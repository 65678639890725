
.header {
    background: $charcoal;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 45px;
    width: 100%;
}
.header__logo {
    -ms-flex: 0 0 95px;
    flex: 0 0 95px;
    text-align: left;
    a {
        display: block;
        svg {
            display: block;
            fill: $white;
            margin: 0 auto;
            height: 48px;
            width: 48px;
        }
    }
}
.product-nav {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    text-align: left;
    margin: 0;
    > li {
        display: inline-block;
        list-style: none;
        margin: 0;
        padding: 0;
        a {
            border-radius: 2px;
            color: $charcoal-40;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1px;
            padding: .25rem .5rem;
            text-transform: uppercase;
            transition: all .15s ease-in-out;
            &:hover {
                color: $white;
                text-decoration: none;
            }
        }
        &.-current a {
            background: $hefe;
            color: $charcoal;
        }
    }
}
.utility-nav {
    -ms-flex: 1;
    flex: 1;
    margin: 0;
    padding: 0 10px 0 0;
    text-align: right;
    > li {
        display: inline-block;
        list-style: none;
        margin: 0;
        padding: 0;
        a {
            display: inline-block;
            padding: 0 .5rem;
            vertical-align: middle;
            &:hover {
                color: $charcoal-20;
                text-decoration: none;
                svg {
                    fill: $white;
                }
            }
            span {
                @include element-invisible;
            }
        }
    }
    .popover {
        width: auto;
        min-width: 140px;
        right: 10px;
        top: 40px;
        text-align: left;
        .user-info {
            color: $white-smoke;
            font-size: 14px;
            font-weight: 300;
        }
        .login-info {
            color: $charcoal-40;
            font-size: 12px;
            font-weight: 300;
            margin-top: 5px;
            span {
                font-weight: normal;
            }
        }
        .account-logout {
            margin: 5px 10px;
            .btn {
                display: block;
                text-align: center;
            }
        }
    }
}

.popover.-arrow {
    &:before {
        content: '';
        background: $charcoal-80;
        height: 10px;
        width: 10px;
        position: absolute;
        display: block;
        top: -5px;
        left: 50%;
        margin: 0 0 0 -5px;
        transform: rotate(45deg);
        z-index: -1;
    }
    &.-account:before {
        right: 11px;
        left: auto;
    }
    &.-help:before {
        right: 46px;
        left: auto;
    }
}


/* Fix later */
nav {
    margin: 0 !important;
    width: auto !important;
}
