/*
 * Lock body scrolling when the create new modal is open
 * Note: This approach works for desktop browsers and mobile Android but not iphones/ipads
 */
body.modal-open {
    overflow: hidden;
}

.primary-action .create-new-push-button {
  position: static;
}

.primary-action-takeover .btn-create.active {
  span {
    display: none;
  }
  &:after {
    content: 'Cancel';
    color: $white;
  }
}

.primary-action-takeover .create-new {
  width: 100%;
  height: 100%;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  background: $grey-20;
  padding: 0;
  border-radius: 0;
  list-style: none;
  box-shadow: none;
  animation: none;
  transform: none;
  transform-origin: top center;
  display: block;
  line-height: 20px;
  position: absolute;
  white-space: normal;

  li {
    list-style: none;
  }

  /*
     * Everything below is simply overriding the dropdown list styles that
     * we use in all of our other popovers. I believe popover is needed for
     * the interactive bits
    */
  li > a {
    border-bottom: none;
    border-top: none;
    color: inherit;
    display: inline;
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
    padding: 0;
    position: relative;
    text-decoration: none;
    z-index: auto;
  }
}


/* Temporary demo styles for Journey Templates */
.journey-templates {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
